import React from 'react';
import styled, {css} from 'styled-components';
import Img from 'gatsby-image';
import {GridComponentContainer, H6, Subtitle} from '../../atoms';
import {colors, devices} from '../../../styles/theme';

const Container = styled(GridComponentContainer)`
  display: grid;
  grid-template-columns: 100px 1fr;
  @media ${devices.tabletPortrait} {
    grid-template-columns: 182px 1fr;
  }
`;

const imageStyle = css`
  object-fit: cover;
  width: 70px;
  height: 70px;
  margin: 0 12px 0 auto;
  @media ${devices.tabletPortrait} {
    width: 96px;
    height: 96px;
    margin: 0 43px 0 auto;
  }
`;

const Image = styled(Img)`${imageStyle}`;
const NativeImage = styled.img`
  ${imageStyle}
  margin: 0;
`;

const Name = styled(H6)`
  color: ${colors.blue};
`;

const JobTitle = styled(Subtitle)`
  color: #647572;
  font-weight: bold;
`;

const Body = styled(Subtitle)`
  color: #62666e;

  a {
    color: ${colors.lightBlue};
    text-decoration: none;
    &:visited {
      color: ${colors.blue};
    }
  }
`;

const ContactPerson = ({contact}) => {
  if (!contact) {
    return null;
  }
  const {name, jobTitle, email, phone, image} = contact;

  return (
    <Container fromColumn={2} widthInColumns={4}>
      {image?.file?.extension === 'svg'
        ? <NativeImage
          src={image?.file?.publicURL}
          alt={image?.alternativeText}
        />
        : <Image
          fluid={image?.file?.childImageSharp?.fluid}
          alt={image?.alternativeText}
        />
      }
      <div>
        <Name>{name}</Name>
        <JobTitle>{jobTitle}</JobTitle>
        <Body>
          <a href={`mailto:${email}`}>{email}</a><br/>
          {phone}
        </Body>
      </div>
    </Container>
  );
};

export default ContactPerson;
