import React from 'react';
import styled from 'styled-components';
import {colors, devices} from '../../styles/theme';
import DynamicZone from './DynamicZone';

const YearLabel = styled.div`
  background: ${colors.orange};
  width: fit-content;
  padding: 4px 8px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  grid-column: 1/9;
  @media ${devices.tabletLandscape} {
    grid-column: ${({startingColumn}) => `${startingColumn}/9`};
  }
`;

const HistoryEvent = ({date, blocks}) => (
  <>
    <YearLabel
      startingColumn={
        blocks[0].__typename === 'STRAPI_ComponentBlocksMediaWithText' &&
        blocks[0].mediaPosition !== 'top'
          ? 2
          : 3
      }
    >
      {new Date(date).getFullYear()}
    </YearLabel>
    <DynamicZone blocks={blocks} center/>
  </>
);

export default HistoryEvent;
