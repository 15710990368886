import React from 'react';
import {Link as GatsbyLink} from 'gatsby';

const isInternal = link => link[0] === '/';

const Link = ({to, ...rest}) => isInternal(to)
  ? <GatsbyLink to={to} {...rest} />
  : <a href={to} target="_blank" rel="noreferrer" {...rest} />;

export default Link;
