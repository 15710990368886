import React from 'react';
import styled, {css} from 'styled-components';
import Img from 'gatsby-image';

import {GridComponentContainer, H1, Subtitle, TextContainer} from '../../atoms';
import {devices, colors} from '../../../styles/theme';
import {parseDate} from '../../../utils/time';
import StoryLabel from '../../molecules/StoryLabel';

const HeaderContainer = styled(GridComponentContainer)`
  display: grid;
  grid-auto-rows: minmax(min-content, max-content);
  padding: 16px 0 4px;
  grid-template-columns: repeat(4, 1fr);
  @media ${devices.tabletPortrait} {
    padding: 24px 0 8px;
  }
  @media ${devices.tabletLandscape} {
    grid-template-columns: repeat(8, 1fr);
    padding: 32px 0 12px;
  }
  @media ${devices.desktop} {
    padding: 40px 0 16px;
  }
`;

const InfoContainer = styled(TextContainer)`
  grid-column: 1/5;
  margin-bottom: 32px;
  @media ${devices.tabletLandscape} {
    grid-column: 2/7;
    margin-bottom: 40px;
  }
  @media ${devices.desktop} {
    margin-bottom: 48px;
  }
`;
const LabelsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
  `;

const Title = styled(H1)`
  color: ${colors.blue};
`;

const Time = styled(Subtitle)`
  color: ${colors.lightBlue};
  font-weight: bold;
`;

const imageStyle = css`
  grid-column: 2/5;
  position: relative;
  @media ${devices.tabletLandscape} {
    grid-column: 3/9;
  }
`;

const ImageContainer = styled(Img)`${imageStyle}`;
const NativeImageContainer = styled.img`
  ${imageStyle}
  margin: 0;
`;

const StoryHeader = ({
  tags,
  storyImage,
  createdAt,
  title
}) =>
  <HeaderContainer>
    <InfoContainer>
      <LabelsContainer>
        {tags.map(({tag, customHexColor, color}) =>
          <StoryLabel key={tag} color={customHexColor || colors[color]} >
            {tag}
          </StoryLabel>
        )}
      </LabelsContainer>
      <Title>{title}</Title>
      {<Time>{parseDate(createdAt)}</Time>}
    </InfoContainer>
    {storyImage?.file
      ? storyImage?.file?.extension === 'svg'
        ? <NativeImageContainer
          src={storyImage?.file?.publicURL}
          alt={storyImage?.alternativeText}
        />
        : <ImageContainer
          fluid={storyImage?.file?.childImageSharp?.fluid}
          alt={storyImage?.alternativeText}
        />
      : null
    }
  </HeaderContainer>;

export default StoryHeader;

