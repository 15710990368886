import React from 'react';
import Img from 'gatsby-image';
import styled from 'styled-components';

import {colors, devices} from '../../styles/theme';
import {Button, RichText, TextContainer, Link} from '../atoms';

const Container = styled.div`
  grid-column: span 2;
  margin: 24px 0;
  transition: filter 0.5s ease;
  ${({disabled}) => disabled && `
    pointer-events: none;
    filter: grayscale(100%);
  `}
  @media ${devices.tabletLandscape} {
    margin: 40px 0;
  }
`;

const ThumbNail = styled(Img)`
  height: 224px;
`;

const SystemInfo = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 8px;
  padding: 24px 16px;
`;

const BlogTitle = styled(RichText)`
  & > p {
    margin: 0;
    font-size: 20px;
    line-height: 1.1;
    @media ${devices.tabletLandscape}{
      font-size: 24px;
    }
    @media ${devices.desktop}{
      font-size: 30px;
    }
    @media ${devices.desktopXL}{
      font-size: 32px;
      line-height: 1;
    }
    color: ${colors.blue};
  }
`;

const SystemPreview = ({system: {name, image, slug}, disabled}) =>
  <Container disabled={disabled}>
    <TextContainer>
      <Link to={`/systems${slug}`}>
        <ThumbNail
          fluid={image?.file.childImageSharp.fluid}
          alt=""
        />
      </Link>
      <SystemInfo>
        <Link to={`/systems${slug}`} style={{textDecoration: 'none'}}>
          <BlogTitle source={name} />
        </Link>
        <Link to={`/systems${slug}`}>
          <Button type='secondary'>
            View system
          </Button>
        </Link>
      </SystemInfo>
    </TextContainer>
  </Container>;

export default SystemPreview;

