import React from 'react';
import styled from 'styled-components';
import {colors, devices} from '../../../styles/theme';
import {Button, GridComponentContainer, H3, RichText, TextContainer, Link} from '../../atoms';

const Container = styled(GridComponentContainer)``;

const PlainTextContainer = styled(TextContainer)`
  padding: 0 16px;
  ${H3} {
    margin-bottom: 8px;
    color: ${colors.blue};
  }
  & > div {
    color: ${colors.xanadu};
    margin-bottom: 8px;
  }
  @media ${devices.tabletPortrait}{
    padding: 0 20px;
    & > div {
      margin-bottom: 12px;
    }
  }
  @media ${devices.desktop}{
    padding: 0 24px;
    & > div {
      margin-bottom: 16px;
    }
  }
`;

const PlainText = ({
  text: {title, body},
  startingColumn,
  button,
  widthInColumns = 5,
  appearAnimation
}) => (
  <Container
    fromColumn={startingColumn}
    widthInColumns={widthInColumns}
    appearFrom={appearAnimation?.appearFrom}
  >
    <PlainTextContainer>
      {title && <H3>{title}</H3>}
      <RichText source={body} />
      {button && (
        <Link to={button.link}>
          <Button type={button.type}>
            {button.text}
          </Button>
        </Link>
      )}
    </PlainTextContainer>
  </Container>
);

export default PlainText;

