import Img from 'gatsby-image';
import styled, {css} from 'styled-components';
import {devices} from '../../styles/theme';

const uspImageStyle = css`
  width: 40px;
  height: 40px;
  @media ${devices.desktop} {
    width: 44px;
    height: 44px;
  }
  @media ${devices.desktopL} {
    width: 48px;
    height: 48px;
  }
  @media ${devices.desktopXL} {
    width: 52px;
    height: 52px;
  }
`;

const UspImage = styled(Img)`${uspImageStyle}`;
export const NativeUSPImage = styled.img`
  ${uspImageStyle}
  margin: 0;
`;

export default UspImage;

