export const hex2rgb = hex => {
  const rgb = hex.substring(1).match(/.{1,2}/g);
  const r = parseInt(rgb[0], 16);
  const g = parseInt(rgb[1], 16);
  const b = parseInt(rgb[2], 16);
  return `rgb(${r}, ${g}, ${b})`;
};

export const hex2rgba = (hex, a) => {
  const rgb = hex.substring(1).match(/.{1,2}/g);
  const r = parseInt(rgb[0], 16);
  const g = parseInt(rgb[1], 16);
  const b = parseInt(rgb[2], 16);
  return `rgba(${r}, ${g}, ${b}, ${a})`;
};

// mimics the color when on white background
export const hex2rgbaTrue = hex => {
  const rgb = hex.substring(1).match(/.{1,2}/g);
  const r = parseInt(rgb[0], 16);
  const g = parseInt(rgb[1], 16);
  const b = parseInt(rgb[2], 16);
  const ref = Math.min(r, g, b);
  const _a = (255 - ref) / 255;
  const _r = (r - ref) / _a;
  const _g = (g - ref) / _a;
  const _b = (b - ref) / _a;
  return `rgba(${_r}, ${_g}, ${_b}, ${_a})`;
};
