import React from 'react';
import useScrollDirection from '../hooks/useScrollDirection';
import {getWindowDimensions} from '../utils/dimensions';
import document from '../utils/document';

const defaultContextValue = {
  isHomePage: false,
  setIsHomePage: () => {},
  isMenuOpen: false,
  setIsMenuOpen: () => {},
  isMenuExpanded: false,
  setIsMenuExpanded: () => {},
  isSearchActive: false,
  setIsSearchActive: () => {},
  hoveredMenuRoute: null,
  setHoveredMenuRoute: () => {},
  location: '/'
};

export const NavigationContext = React.createContext(defaultContextValue);

const NavigationProvider = ({children}) => {
  const [isHomePage, setIsHomePage] = React.useState(false);
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [isMenuExpanded, setIsMenuExpanded] = React.useState(false);
  const [isSearchActive, setIsSearchActive] = React.useState(false);
  const [hoveredMenuRoute, setHoveredMenuRoute] = React.useState(null);
  const [location, setLocation] = React.useState('/');
  const [hideTopBar, setHideTopBar] = React.useState(false);
  const scrollDirection = useScrollDirection();
  React.useEffect(() => {
    setHideTopBar(scrollDirection === 'down');
  }, [scrollDirection]);
  React.useEffect(() => {
    const {width} = getWindowDimensions();
    document.documentElement.style.overflowY = (isMenuOpen && width < 600) ? 'hidden' : 'auto';
  }, [isMenuOpen]);
  return (
    <NavigationContext.Provider value={{
      isHomePage,
      setIsHomePage,
      isMenuOpen,
      setIsMenuOpen,
      isMenuExpanded,
      setIsMenuExpanded,
      isSearchActive,
      setIsSearchActive,
      hoveredMenuRoute,
      setHoveredMenuRoute,
      hideTopBar,
      location,
      setLocation
    }}>
      {children}
    </NavigationContext.Provider>
  );
};

export default NavigationProvider;
