/* eslint-disable atomic-design/hierarchical-import */
import styled from 'styled-components';
import {hex2rgb, hex2rgba} from '../../../utils/hex2rgb';
import {colors, devices} from '../../../styles/theme';
import {H2, H6, Link} from '../../atoms';

const h2FontSizes = {
  s: 26,
  m: 32,
  l: 32,
  xl: 42
};

const topBarHeight = {
  s: 44,
  m: 44,
  l: 56,
  xl: 56
};

const topBarTopPadding = {
  s: 8,
  m: 8,
  l: 16,
  xl: 16
};

const h2LineHeightPercentage = {
  s: 1.25,
  m: 1.25,
  l: 1.25,
  xl: 1.15
};

const menuStyles = {
  topContainer: {
    paddingBottom: {
      s: 24,
      m: 28,
      l: 32,
      xl: 36
    }
  },
  mainRoute: {
    margin: {
      s: 16,
      m: 16,
      l: 16,
      xl: 24
    },
    lineHeight: {
      s: h2FontSizes.s * h2LineHeightPercentage.s,
      m: h2FontSizes.m * h2LineHeightPercentage.m,
      l: h2FontSizes.l * h2LineHeightPercentage.l,
      xl: h2FontSizes.xl * h2LineHeightPercentage.xl
    }
  },
  secondaryRoute: {
    margin: {
      s: 12,
      m: 12,
      l: 12,
      xl: 16
    }
  }
};

const menuWidth = {
  l: 304,
  xl: 384,
};

const mainRouteHeight = breakpoint =>
  menuStyles.mainRoute.lineHeight[breakpoint] + 2 * menuStyles.mainRoute.margin[breakpoint];

const deepMenuTop = (hoveredIndex, breakpoint) =>
  topBarTopPadding[breakpoint] +
  topBarHeight[breakpoint] +
  menuStyles.topContainer.paddingBottom[breakpoint] +
  (hoveredIndex - 1) * mainRouteHeight(breakpoint);

export const MenuContainer = styled.div`
  opacity: 1;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 5;
  background: ${colors.blue};
  height: 100vh;
  width: ${menuWidth.l}px;
  display: flex;
  flex-direction: column;
  padding-top: 8px;
  transform: translateX(-100%);
  transition: all 400ms;
  &.open-enter {
    transform: translateX(-100%);
  }
  &.open-enter-active {
    transform: translateX(0%);
  }
  &.open-enter-done {
    transform: translateX(0%);
  }
  &.open-exit {
    transition-delay: 200ms;
    transform: translateX(0%);
  }
  &.open-exit-active {
    transform: translateX(-100%);
  }
  &.open-exit-done {
    transform: translateX(-100%);
  }
  @media ${devices.desktop} {
    padding-top: 16px;
    width: ${menuWidth.xl}px;
  }
`;

export const DesktopMenuContainer = styled(MenuContainer)`
  display: none;
  @media ${devices.tabletPortrait} {
    display: flex;
  }
`;

export const MobileMenuContainer = styled(MenuContainer)`
  display: flex;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition-delay: 0ms !important;
  @media ${devices.tabletPortrait} {
    display: none;
  }
`;

export const DeepMenuContainer = styled.div`
  display: none;
  position: fixed;
  top: 0px;
  background: ${colors.blue};
  height: 100vh;
  z-index: 5;
  flex-direction: column;
  padding: 16px;
  transform: scaleX(0);
  transform-origin: 0px;
  transition: transform 200ms;
  &.deep-menu-container-enter {
    transform: scaleX(0);
  }
  &.deep-menu-container-enter-active {
    transform: scaleX(1);
  }
  &.deep-menu-container-enter-done {
    transform: scaleX(1);
  }
  &.deep-menu-container-exit {
    transform: scaleX(1);
  }
  &.deep-menu-container-exit-active {
    transform: scaleX(0);
  }
  &.deep-menu-container-exit-done {
    transform: scaleX(0);
  }
  @media ${devices.tabletPortrait} {
    width: ${menuWidth.l}px;
    left: ${menuWidth.l}px;
    display: flex;
  }
  @media ${devices.desktop} {
    width: ${menuWidth.xl}px;
    left: ${menuWidth.xl}px;
  }
`;

export const TopContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 0 8px;
  padding-bottom: ${menuStyles.topContainer.paddingBottom.s}px;
  @media ${devices.tabletLandscape} {
    padding-bottom: ${menuStyles.topContainer.paddingBottom.m}px;
  }
  @media ${devices.desktop} {
    padding: 0 16px;
    padding-bottom: ${menuStyles.topContainer.paddingBottom.l}px;
  }
  @media ${devices.desktopXL} {
    padding-bottom: ${menuStyles.topContainer.paddingBottom.xl}px;
  }
`;

export const NavigationContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
`;

export const DeepRouteLink = styled.div`
  padding: 0 20%;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
  ${({hovered}) => hovered && `box-shadow: 0 0 64px 0 ${hex2rgba(colors.deepBlue, 0.8)}`};
  ${({hovered}) => hovered && `background: ${colors.blue}`};
`;

export const RouteName = styled(Link)`
  text-decoration: none;
  display: flex;
  &:hover {
    ${H2} {
      color: ${hex2rgb(colors.lightBlue)};
      &::after {
        border-color: ${hex2rgb(colors.lightBlue)};
        transform: scale(1);
      }
    }
    ${H6} {
      color: ${hex2rgb(colors.lightBlue)};
      &::after {
        border-color: ${hex2rgb(colors.lightBlue)};
        transform: scale(1);
      }
    }
  }
  ${H2} {
    width: fit-content;
    position: relative;
    color: rgb(255, 255, 255);
    margin: ${menuStyles.mainRoute.margin.s}px 0;
    margin-left: 20%;
    transition: color 150ms;
    @media ${devices.tabletLandscape} {
      margin: ${menuStyles.mainRoute.margin.m}px 0;
      margin-left: 20%;
      &::after {
        transition: transform 150ms;
      }
    }
    @media ${devices.desktop} {
      margin: ${menuStyles.mainRoute.margin.l}px 0;
      margin-left: 20%;
    }
    @media ${devices.desktopXL} {
      margin: ${menuStyles.mainRoute.margin.xl}px 0;
      margin-left: 20%;
    }
    &::after {
      content: '';
      position: absolute;
      bottom: -2px;
      width: 100%;
      transform: scale(0);
      border-bottom: 1px solid transparent;
      display: block;
    }
  }
  ${H6} {
    width: fit-content;
    position: relative;
    margin: 30px 0 30px 20%;
    color: rgb(255, 255, 255);
    transition: color 150ms;
    margin: ${menuStyles.secondaryRoute.margin.s}px 0;
    margin-left: 20%;
    @media ${devices.tabletLandscape} {
      margin: ${menuStyles.secondaryRoute.margin.m}px 0;
      margin-left: 20%;
      &::after {
        transition: transform 150ms;
      }
    }
    @media ${devices.desktop} {
      margin: ${menuStyles.secondaryRoute.margin.l}px 0;
      margin-left: 20%;
    }
    @media ${devices.desktopXL} {
      margin: ${menuStyles.secondaryRoute.margin.xl}px 0;
      margin-left: 20%;
    }
    &::after {
      content: '';
      position: absolute;
      bottom: -2px;
      width: 100%;
      transform: scale(0);
      border-bottom: 1px solid transparent;
      display: block;
    }
  }
`;

export const MobileRouteName = styled(RouteName)`
  ${H6} {
    color: white !important;
    &::after {
      border-color: white !important;
    }
  }
  ${H2} {
    color: white !important;
    &::after {
      border-color: white !important;
    }
  }
`;

export const Arrow = styled.p`
  ${({hovered}) => `
    transform: rotate(${hovered ? '-90deg' : '90deg'});
  `}
  color: white;
  font-size: 12px;
  transition: all 150ms;
  margin: 0;
  @media ${devices.tabletPortrait} {
    font-size: 12px;
    ${({hovered}) => `
      color: ${hovered ? `${hex2rgb(colors.lightBlue)}` : 'rgb(255, 255, 255)'};
      transform: translateX(${hovered ? '4px' : 0});
    `}
  }
  @media ${devices.desktop} {
    font-size: 14px;
  }
`;

export const DeepMainRouteName = styled(H2)`
  color: rgb(255, 255, 255);
  width: fit-content;
  transition: color 150ms;
  position: relative;
  margin: ${menuStyles.mainRoute.margin.s}px 0;
  @media ${devices.tabletLandscape} {
    margin: ${menuStyles.mainRoute.margin.m}px 0;
  }
  @media ${devices.desktop} {
    margin: ${menuStyles.mainRoute.margin.l}px 0;
  }
  @media ${devices.desktopXL} {
    margin: ${menuStyles.mainRoute.margin.xl}px 0;
  }
  ${({hovered}) => hovered && `color: ${hex2rgb(colors.lightBlue)}`};
  &::after {
    content: '';
    position: absolute;
    bottom: -2px;
    width: 100%;
    transform: scale(0);
    transition: transform 150ms;
    border-bottom: 1px solid transparent;
    display: block;
    ${({hovered}) => hovered && `
      border-color: ${hex2rgb(colors.lightBlue)};
      transform: scale(1);
    `}
  }
`;

export const MobileDeepRouteLink = styled(DeepRouteLink)`
  background: transparent;
  box-shadow: none;
  ${DeepMainRouteName} {
    color: white;
    &::after {
      border-color: white;
    }
  }
`;

export const DeepMenu = styled.div`
  position: absolute;
  z-index: 2;
  opacity: 1;
  left: 0;
  width: ${menuWidth.l}px;
  display: flex;
  flex-direction: column;
  padding: 0 48px;
  transition: all 200ms;
  top: ${({hovered}) => deepMenuTop(hovered, 's')}px;
  @media ${devices.tabletLandscape} {
    top: ${({hovered}) => deepMenuTop(hovered, 'm')}px;
  }
  @media ${devices.desktop} {
    width: ${menuWidth.xl}px;
    top: ${({hovered}) => deepMenuTop(hovered, 'l')}px;
  }
  @media ${devices.desktopXL} {
    top: ${({hovered}) => deepMenuTop(hovered, 'xl')}px;
  }
`;

export const DeepLink = styled.div`
  display: flex;
  align-items: center;
  min-height: 48px;
  padding: 4px 0;
  @media ${devices.tabletPortrait} {
    min-height: ${mainRouteHeight('s')}px;
  }
  @media ${devices.tabletLandscape} {
    min-height: ${mainRouteHeight('m')}px;
    padding: 10px 0;
  }
  @media ${devices.desktop} {
    min-height: ${mainRouteHeight('l')}px;
  }
  @media ${devices.desktopXL} {
    min-height: ${mainRouteHeight('xl')}px;
    padding: 14px 0;
  }
`;

export const DeepLinkName = styled(H6)`
  color: white;
  font-weight: normal;
  position: relative;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 150ms;
  &::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    transform: scale(0);
    transition: all 150ms;
    border-bottom: 1px solid transparent;
  }
  &:hover {
    color: ${hex2rgb(colors.lightBlue)};
    &::after {
      border-color: ${hex2rgb(colors.lightBlue)};
      transform: scale(1);
    }
  }
  @media ${devices.tabletPortrait} {
    &::after {
      bottom: -3px;
    }
  }
  @media ${devices.desktop} {
    &::after {
      bottom: -4px;
    }
  }
`;
export const CloseIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 44px;
  width: 44px;
  & > svg {
    height: 28px;
    width: 28px;
    path {
      fill: rgb(255, 255, 255);
      transition: fill 600ms;
    }
  }
  @media ${devices.tabletLandscape} {
    margin-right: 12px;
    &:hover {
      & > svg > path {
        fill: ${hex2rgb(colors.lightBlue)};
      }
    }
  }
  @media ${devices.desktop} {
    margin-right: 16px;
    height: 56px;
    width: 56px;
    & > svg {
      height: 36px;
      width: 36px;
    }
  }
  @media ${devices.desktopXL} {
    margin-right: 24px;
  }
`;

export const MenuHomeLink = styled(Link)`
  height: 44px;
  @media ${devices.desktop} {
    height: 56px;
  }
`;

export const AmpelmannLogo = styled.img`
  background: ${colors.blue};
  height: 44px;
  margin-bottom: 0;
  width: 212px;
  transition: opacity 2000ms;
  @media ${devices.desktop} {
    height: 56px;
    width: 272px;
  }
`;

export const AmpelmannLogoShort = styled.img`
  background: ${colors.blue};
  height: 44px;
  margin-bottom: 0;
  width: 44px;
  cursor: pointer;
  @media ${devices.desktop} {
    height: 56px;
    width: 56px;
  }
`;

export const MainRoutes = styled.div`
  margin-bottom: 32px;
  @media ${devices.tabletLandscape} {
    margin-bottom: 36px;
  }
  @media ${devices.desktop} {
    margin-bottom: 40px;
  }
  @media ${devices.desktopXL} {
    margin-bottom: 48px;
  }
`;

export const SecondaryRoutes = styled.div`
  margin-bottom: 12px;
  @media ${devices.tabletLandscape} {
    margin-bottom: 16px;
  }
  @media ${devices.desktop} {
    margin-bottom: 20px;
  }
  @media ${devices.desktopXL} {
    margin-bottom: 24px;
  }
`;

export const OtherRoutes = styled.div``;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${({disabled}) => `cursor: ${disabled ? 'auto' : 'pointer'}`};
  ${({color, inverted}) => `
    background-color: ${inverted ? hex2rgb(color) : 'transparent'};
  `};
  ${({color, inverted, disabled}) => disabled && `
    background-color: ${inverted ? `${hex2rgb(color)}` : 'transparent !important'};
  `};
  transition: background-color 300ms;
  height: 44px;
  width: 44px;
  &:nth-child(3) {
    border-radius: 4px 0 0 4px;
  }
  @media ${devices.tabletPortrait} {
    &:nth-child(2) {
    border-radius: 4px 0 0 4px;
    }
    &:nth-child(3) {
      border-radius: 0px;
    }
    &:hover {
      ${({color, inverted}) => `
        background-color: ${inverted ? 'white' : hex2rgb(color)};
      `};
      ${({color, inverted, disabled}) => disabled && `
        background-color: ${inverted ? `${hex2rgb(color)}` : 'transparent !important'};
      `};
      & svg > path {
        ${({color, inverted}) => `fill: ${inverted ? hex2rgb(color) : 'rgb(255, 255, 255)'}`};
        ${({color, inverted, disabled}) => disabled && `
          fill: ${inverted ? 'white' : `${hex2rgb(color)}`};
        `};
      }
    }
  }
  @media ${devices.desktop} {
    height: 56px;
    width: 56px;
  }
  a {
    display: flex;
  }
  svg {
    height: 28px;
    width: 28px;
    @media ${devices.desktop} {
      height: 36px;
      width: 36px;
    }
    & > path {
      ${({color, inverted}) => `fill: ${inverted ? 'rgb(255, 255, 255)' : hex2rgb(color)};`};
      transition: fill 300ms;
    }
  }
`;

export const CareersButton = styled.div`
  border-radius: 4px;
  border: 1px solid #112369;
  align-items: center;
  justify-content: center;
  color: #112369;
  font-size: 14px;
  height: 44px;
  padding: 0 12px;
  margin-right: 12px;
  cursor: pointer;
  display: none;
  text-transform: uppercase;
  @media ${devices.tabletPortrait} {
    display: flex;
  }
  @media ${devices.desktop} {
    height: 56px;
    font-size: 18px;
    padding: 0 16px;
    margin-right: 16px;
  }
  &:hover {
    background: ${colors.lightGrey};
  }
`;
