/* eslint-disable complexity */
import React, {useState, useRef} from 'react';
import styled from 'styled-components';
import _Checkbox from 'rc-checkbox';
import ReCAPTCHA from 'react-google-recaptcha';
import {ArrowButton, GridComponentContainer, H1, H6, Body, RichText, Link} from '../../atoms';
import {colors, devices} from '../../../styles/theme';
import TextField from '../Textfield';
import {emailRegex} from '../../../utils/regex';
import mapImg from '../../../images/amp_map.svg';
import useAsync from '../../../hooks/useAsync';

const ContactContainer = styled(GridComponentContainer)`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  @media ${devices.tabletLandscape} {
    grid-template-columns: repeat(8, 1fr);
  }
`;

const Checkbox = styled(_Checkbox)`
  margin-right: 8px;
  line-height: 1;
  & > input {
    width: 16px;
    height: 16px;
    border: none;
    position: relative;
    &::after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      border: 2px solid ${({error}) => error ? colors.rose : colors.lightBlue};
      border-radius: 2px;
    }
  }
`;

const FindExpert = styled.div`
  position: relative;
  grid-column: span 4;
  grid-row: 2 / 3;
  padding: 20px 16px 24px;
  background: ${colors.orange};
  color: white;
  margin-bottom: 8px;
  &:before {
    content: ' ';
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.2;
    background-image: url(${mapImg});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  h5, button  {
    position: relative;
  }
  @media ${devices.tabletPortrait} {
    padding: 24px 20px 32px;
  }
  @media ${devices.tabletLandscape} {
    padding: 32px 24px 40px;
    grid-column: span 8;
    &:before {
      width: 50%;
    }
  }
  @media ${devices.desktop} {
    margin-bottom: 16px;
  }
`;

const ExpertTextContainer = styled.div`
  width: 100%;
  @media ${devices.tabletLandscape} {
    width: 50%;
  }
`;

const AcceptTerms = styled.div`
  display: flex;
  align-items: center;
`;

const ExpertText = styled(Body)`
  margin: 12px 0 24px;
`;

const FormHeader = styled.div`
  grid-column: span 4;
  grid-row: 1 / 2;
`;

const FormTitle = styled(H6)`
  font-weight: bold;
  margin-bottom: 4px;
  color: ${colors.blue};
`;

const Title = styled(H1)`
  margin-bottom: 16px;
  padding: 0 16px;
  @media ${devices.tabletPortrait} {
    padding: 0 20px;
    margin-bottom: 20px;
  }
  @media ${devices.tabletLandscape} {
    padding: 0;
    margin-bottom: 24px;
  }
  font-weight: bold;
  grid-column: span 4;
  grid-row: 1 / 2;
  color: ${colors.blue};
`;

const FormContainer = styled.div`
  grid-column: span 8;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
  padding: 16px;
  grid-gap: 16px;
  @media ${devices.tabletPortrait}{
    padding: 20px;
    grid-gap: 20px;
  }
  @media ${devices.tabletLandscape} {
    grid-gap: 24px;
    grid-template-columns: repeat(8, 1fr);
    padding: 24px;
  }
`;

const ContactInfo = styled.div`
  grid-column: span 4;
  grid-row: 2 / 3;
`;

const MessageInfo = styled.div`
  grid-column: span 4;
  grid-row: 3 / 4;
  @media ${devices.tabletLandscape} {
    grid-row: 2 / 3;
  }
`;

const SubmitContainer = styled.div`
  grid-column: span 4;
  grid-row: 4 / 5;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-end;
  @media ${devices.tabletLandscape} {
    align-items: center;
    grid-row: 3 / 4;
    grid-column: 4 / 9;
  }
  @media ${devices.desktop} {
    grid-column: 5 / 9;
  }
  & > div {
    display: flex;
    width: 100%;
    flex-direction: row;
    &:first-child {
      margin-bottom: 24px;
    }
  }
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
`;

const Error = styled.div`
  color: ${colors.rose};
  background: ${colors.rose}30;
  border: 1px solid ${colors.rose}60;
  border-radius: 2px;
  padding: 8px 12px;
  line-height: 24px;
  font-size: 16px;
  grid-row: 5 / 6;
  grid-column: span 4;
  a {
    color: inherit;
  }
  @media ${devices.tabletLandscape} {
    grid-row: 4 / 5;
    grid-column: 4 / 9;
  }
  @media ${devices.desktop} {
    grid-column: 5 / 9;
  }
`;

const Success = styled(Error)`
  color: ${colors.green};
  background: ${colors.green}30;
  border-color: ${colors.green}60;
`;

const checkEmpty = field => field.length === 0 && [{message: 'This field is required'}];

const checkEmail = email => !emailRegex.test(email) && [{message: 'E-mail invalid'}];

const submitForm = formValues => fetch(`${process.env.GATSBY_API_ROOT}/contact-us`, {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
  },
  body: JSON.stringify(formValues),
});

const ContactUs = ({
  title,
  expertTitle,
  expertSubtitle,
  expertButtonText,
  formTitle,
  formSubtitle,
  acceptTermsText,
  appearAnimation,
  hideExpertSection
}) => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [company, setCompany] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const recaptchaRef = useRef();
  const isValid = emailRegex.test(email) &&
    name &&
    company &&
    subject &&
    hasAcceptedTerms &&
    recaptchaToken !== null;

  const {execute: submit, status, value} = useAsync(submitForm, false);

  return (
    <ContactContainer
      fromColumn={2}
      widthInColumns={6}
      appearFrom={appearAnimation?.appearFrom}
    >
      {!hideExpertSection && (
        <>
          <Title>{title}</Title>
          <FindExpert>
            <ExpertTextContainer>
              <H6 style={{fontWeight: 'bold'}}>{expertTitle}</H6>
              <ExpertText>{expertSubtitle}</ExpertText>
              <Link to="/contact">
                <ArrowButton>{expertButtonText}</ArrowButton>
              </Link>
            </ExpertTextContainer>
          </FindExpert>
        </>
      )}
      <FormContainer>
        <FormHeader>
          <FormTitle>{formTitle}</FormTitle>
          <Body style={{color: colors.grey}}>{formSubtitle}</Body>
        </FormHeader>
        <ContactInfo>
          <TextField
            label="Name"
            required
            value={name}
            onChange={e => setName(e.target.value)}
            type="text"
            errors={showErrors && checkEmpty(name)}
          />
          <TextField
            label="E-mail address"
            required
            value={email}
            onChange={e => setEmail(e.target.value)}
            type="email"
            errors={showErrors && checkEmail(email)}
          />
          <Row>
            <TextField
              label="Company name"
              required
              value={company}
              onChange={e => setCompany(e.target.value)}
              type="text"
              errors={showErrors && checkEmpty(company)}
            />
            <TextField
              label="Phone number"
              value={phoneNumber}
              onChange={e => setPhoneNumber(e.target.value)}
              type="tel"
            />
          </Row>
        </ContactInfo>
        <MessageInfo>
          <TextField
            label="Subject"
            required
            value={subject}
            onChange={e => setSubject(e.target.value)}
            type="text"
            errors={showErrors && checkEmpty(subject)}
          />
          <TextField
            label="Message"
            value={message}
            onChange={e => setMessage(e.target.value)}
            multiline
            type="text"
          />
        </MessageInfo>
        <SubmitContainer>
          <div>
            <ReCAPTCHA
              sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY}
              ref={recaptchaRef}
              onChange={token => setRecaptchaToken(token)}
            />
          </div>
          <div>
            <AcceptTerms>
              <Checkbox
                checked={hasAcceptedTerms}
                error={showErrors && !hasAcceptedTerms}
                onChange={e => setHasAcceptedTerms(e.target.checked)}
              />
              <RichText source={acceptTermsText}/>
            </AcceptTerms>
            <ArrowButton
              type="secondary"
              style={{marginLeft: 'auto'}}
              disabled={status === 'pending'}
              onClick={
                isValid
                  ? () => submit({
                    email,
                    name,
                    company,
                    phoneNumber: phoneNumber || 'Not specified',
                    subject,
                    message: message || 'Not specified',
                    recaptchaRes: recaptchaToken
                  })
                  : () => setShowErrors(true)
              }
            >
              Send
            </ArrowButton>
          </div>
        </SubmitContainer>
        {showErrors && recaptchaToken === null && (
          <Error>
            Please complete the ReCAPTCHA challenge. This is needed for us to
            combat automated spammers.
          </Error>
        )}
        {value && !value.ok && (
          <Error>
            There was a problem submitting the form. Please try again later or
            send an email to <a href="mailto:sales@ampelmann.nl">sales@ampelmann.nl</a>.
          </Error>
        )}
        {value && value.ok && (
          <Success>
            Thank you for contacting us. We will be in touch soon.
          </Success>
        )}
      </FormContainer>
    </ContactContainer>
  );
};

export default ContactUs;
