import React from 'react';
import styled from 'styled-components';
import ReactSlick from 'react-slick';
import useResize from '../../../hooks/useResize';
import {colors, devices} from '../../../styles/theme';
import {getRefDimensions} from '../../../utils/dimensions';
import {
  H4,
  RichText,
  GridComponentContainer,
  Media,
  TextContainer,
  Link,
  Button
} from '../../atoms';
import {hex2rgb} from '../../../utils/hex2rgb';

const InfoContainer = styled(TextContainer)`
  grid-column: 1 / 5;
  padding: 18px;
  ${H4} {
    font-weight: bold;
    margin-bottom: 16px;
    color: ${colors.blue};
  }
  & > div {
    margin: 12px 0 4px;
  }
  @media ${devices.tabletPortrait}{
    ${H4} {
      margin-bottom: 24px;
    }
    & > div {
      margin: 20px 0 8px;
    }
  }
  @media ${devices.tabletLandscape}{
    padding: 20px;
    grid-column: 6 / 9;
  }
  @media ${devices.desktopXL}{
    padding: 24px;
    ${H4} {
      margin-bottom: 32px;
    }
    & > div {
      margin: 24px 0 12px;
    }
  }
  `;

const MediaContainer = styled.div`
  grid-column: 1 / 5;
  @media ${devices.tabletLandscape}{
    grid-column: 1 / 6;
  }
`;

const Slider = styled(ReactSlick)`
  &.slick-slider .slick-list .slick-track .slick-slide {
    height: auto;
    pointer-events: none;
  }
  &.slick-slider .slick-list .slick-track .slick-active {
    pointer-events: auto;
  }
`;

const Container = styled(GridComponentContainer)`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(4, 1fr);
  box-shadow: 0px 0px 48px rgba(77,81,88,0.11);
  @media ${devices.tabletLandscape} {
    grid-template-columns: repeat(8, 1fr);
  }
`;

const SlideLink = styled.p`
  font-size: 16px;
  margin: 0 0 4px 0;
  width: fit-content;
  cursor: pointer;
  position: relative;
  transition: all 150ms;
  ${({selected}) => `
    color: ${selected ? `${colors.blue} !important` : colors.xanadu};
    &::after {
      border-bottom: 1px solid ${selected ? `${colors.blue} !important` : colors.xanadu};
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      transform: scale(${selected ? 1 : 0});
      transition: transform 150ms;
    }
  `}
  &:hover {
    color: ${hex2rgb(colors.lightBlue)};
    &:after {
      transform: scale(1);
      border-bottom: 1px solid ${({selected}) => selected ? colors.blue : colors.lightBlue};
    }
  }
  @media ${devices.tabletLandscape} {
    margin: 0 0 8px 0;
    font-size: 18px;
  }
`;

const Carousel = ({title, slides, appearAnimation}) => {
  const mediaRef = React.useRef();
  const infoRef = React.useRef();
  const resizeTrigger = useResize();
  const [mediaWidth, setMediaWidth] = React.useState(0);
  const [mediaHeight, setMediaHeight] = React.useState(0);
  const [selectedSlide, setSelectedSlide] = React.useState(slides[0]);
  React.useEffect(() => {
    setMediaWidth(getRefDimensions(mediaRef).width);
    setMediaHeight(getRefDimensions(infoRef).height);
  }, [resizeTrigger]);
  const sliderSettings = {
    arrows: false,
    speed: 500,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    easing: 'ease-out',
    beforeChange: (_, newIndex) => setSelectedSlide(slides[newIndex])
  };
  const sliderRef = React.useRef();
  return (
    <Container
      fromColumn={1}
      widthInColumns={8}
      appearFrom={appearAnimation?.appearFrom}
    >
      <MediaContainer ref={mediaRef}>
        <Slider {...sliderSettings} ref={sliderRef}>
          {slides.map(slide => (
            <Media
              key={slide.title}
              media={slide.media}
              mediaWidth={mediaWidth}
              // 24 is the total vertical margin of the text container
              height={mediaHeight + 24}
            />

          ))}
        </Slider>
      </MediaContainer>
      <InfoContainer ref={infoRef}>
        <H4>{title}</H4>
        {slides.map((slide, index) => (
          <SlideLink
            key={slide.id}
            onClick={() => sliderRef.current.slickGoTo(index)}
            selected={slide.id === selectedSlide.id}
          >
            {slide.title}
          </SlideLink>
        ))}
        <RichText source={selectedSlide.text} />
        {selectedSlide.button && (
          <Link to={selectedSlide.button.link}>
            <Button type={selectedSlide.button.type}>
              {selectedSlide.button.text}
            </Button>
          </Link>
        )}
      </InfoContainer>
    </Container>
  );
};

export default Carousel;
