import React from 'react';
import styled from 'styled-components';
import {colors, devices} from '../../../styles/theme';
import {GridComponentContainer, UspImage} from '../../atoms';
import {getRefDimensions} from '../../../utils/dimensions';
import useResize from '../../../hooks/useResize';
import {hex2rgbaTrue} from '../../../utils/hex2rgb';
import {NativeUSPImage} from '../../atoms/UspImage';

const Container = styled(GridComponentContainer)`
  display: grid;
  grid-auto-flow: row;
  grid-template-rows: ${({noOfTiles}) => `repeat(${noOfTiles}, auto)`};
  grid-template-columns: repeat(4, 1fr);
  @media ${devices.tabletLandscape} {
    grid-auto-flow: column;
    grid-template-rows: 1fr;
    grid-template-columns: ${({noOfTiles}) => `repeat(${noOfTiles}, 1fr)`};
  }
`;

const Tile = styled.div`
  background: ${hex2rgbaTrue(colors.lightGrey)};
  grid-row: ${({index}) => `${index + 1} / ${index + 2}`};
  grid-column: 2 / 4;
  display: flex;
  flex-direction: row;
  padding: 8px;
  height: auto;
  @media ${devices.tabletLandscape} {
    grid-row: 1 / 2;
    ${({tilewidth}) => `height: ${tilewidth}px`};
    grid-column: ${({index}) => `${index + 1} / ${index + 2}`};
    flex-direction: column;
  }
  @media ${devices.desktop} {
    padding: 16px;
  }
`;

const ImageContainer = styled.div`
  flex: 0.25;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  @media ${devices.tabletLandscape} {
    padding: 0;
    flex: 0.5;
    width: 100%;
  }
`;

const TextContainer = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media ${devices.tabletLandscape} {
    align-items: flex-start;
    flex: 0.4;
  }
`;

const Text = styled.p`
  margin: 0;
  text-align: center;
  color: ${colors.blue};
  font-weight: bold;
  font-size: 12px;
  line-height: 1.5;
  @media ${devices.tabletLandscape} {
    font-size: 13px;
  }
  @media ${devices.desktopXL} {
    font-size: 16px;
  }
`;

const USP = ({USPTiles, startingColumn, appearAnimation}) => {
  const noOfTiles = USPTiles.length;
  const resizeTrigger = useResize();
  const [tileWidth, setTileWidth] = React.useState(0);
  const tileRef = React.useRef();
  React.useEffect(() => {
    setTileWidth(getRefDimensions(tileRef).width);
  }, [resizeTrigger]);
  return (
    <Container
    // Adjust the starting column accordingly based on number of tiles
      fromColumn={Math.min(8 - (noOfTiles - 1), startingColumn)}
      widthInColumns={noOfTiles}
      noOfTiles={noOfTiles}
      appearFrom={appearAnimation?.appearFrom}
    >
      {USPTiles.map((tile, index) => (
        <Tile
          key={tile.text}
          ref={tileRef}
          index={index}
          tilewidth={tileWidth}
        >
          <ImageContainer>
            {tile.icon?.file?.extension === 'svg'
              ? <NativeUSPImage
                src={tile.icon?.file?.publicURL}
                alt={tile.icon?.alternativeText}
              />
              : <UspImage
                fluid={tile.icon?.file?.childImageSharp?.fluid}
                alt={tile.icon?.alternativeText}
              />
            }
          </ImageContainer>
          <TextContainer>
            <Text>{tile.text}</Text>
          </TextContainer>
        </Tile>
      ))}
    </Container>
  );
};

export default USP;
