import React from 'react';
import styled from 'styled-components';

import {colors} from '../../styles/theme';

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  color: ${colors.blue};
  /* line-height: 24px; */
  /* padding-right: 8px; */
  :hover {
    > div:first-of-type {
      background: ${colors.lightBlueLight}55;
      box-shadow: 0 0 0 2px ${colors.lightBlueLight}55;
    }
  }

  input[type="checkbox"] {
    cursor: pointer;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: block;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    border: none;
    appearance: none;
  }
  > div:first-of-type {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 24px;
    height: 24px;
    margin-right: 8px;
    border: 3px solid ${colors.lightBlue};
    border-radius: 50%;
    transition: background 0.2s linear, box-shadow 0.2s, border 0.2s linear;
    > span {
      transition: opacity 0.2s linear;
      opacity: ${props => props.checked ? 1 : 0};
      background: ${colors.blue};
      height: 12px;
      width: 12px;
      border-radius: 50%;
      pointer-events: none;
    }
  }
`;

const Radio = ({
  checked,
  children,
  className,
  ...props
}) =>
  <Container checked={checked} className={className}>
    <input type="checkbox" checked={checked} {...props} />{' '}
    <div>
      <span></span>
    </div>
    {children}
  </Container>;

export default Radio;
