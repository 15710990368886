import {graphql, useStaticQuery} from 'gatsby';

const useNavigationData = () => {
  const data = useStaticQuery(graphql`
    query {
      strapi {
        markets(sort: "displayOrder") {
          name: displayName
          to: slug
        }
        services {
          name,
          to: slug
        }
        navigation {
          contact {
            title
            subtitle
            button {
              ...Button
            }
          }
        }
      }
    }
  `);

  return ({
    contact: data.strapi.navigation?.contact,
    routes: {
      main: [
        {
          name: 'Markets',
          isDeep: true,
          subRoutes: data.strapi.markets
        },
        {
          name: 'Services',
          isDeep: true,
          subRoutes: data.strapi.services,
        },
        {
          name: 'Systems',
          isDeep: false,
          to: '/systems'
        },
        {
          name: 'Sustainability',
          isDeep: false,
          to: '/sustainability',
        },
      ],
      secondary: [
        {
          name: 'Projects',
          to: '/projects'
        },
        {
          name: 'Stories',
          to: '/stories'
        }
      ],
      rest: [
        {
          name: 'News and events',
          to: '/news'
        },
        {
          name: 'History',
          to: '/history'
        },
        {
          name: 'Careers',
          to: 'https://careers.ampelmann.nl'
        },
      ],
    }
  });
};

export default useNavigationData;
