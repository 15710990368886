import React from 'react';
import styled from 'styled-components';
import {GridComponentContainer, Media} from '../../atoms';
import useResize from '../../../hooks/useResize';
import {getRefDimensions} from '../../../utils/dimensions';

const Container = styled(GridComponentContainer)``;

const MediaBlock = ({startingColumn, media, appearAnimation}) => {
  const mediaRef = React.useRef();
  const resizeTrigger = useResize();
  const [mediaWidth, setMediaWidth] = React.useState(0);
  React.useEffect(() => {
    setMediaWidth(getRefDimensions(mediaRef).width);
  }, [resizeTrigger]);
  return (
    <Container
      ref={mediaRef}
      fromColumn={startingColumn}
      widthInColumns={4}
      appearFrom={appearAnimation?.appearFrom}
    >
      <Media
        media={media}
        mediaWidth={mediaWidth}
      />
    </Container>
  );
};

export default MediaBlock;
