import styled from 'styled-components';

import {colors} from '../../styles/theme';
import Arrow from '../../images/arrow.svg';

const buttonColors = {
  primary: {
    default: colors.blue,
    hover: colors.blueDark,
    pressed: colors.blueDarker,
    disabled: colors.blueLight,
  },
  secondary: {
    default: colors.lightBlue,
    hover: colors.lightBlueDark,
    pressed: colors.lightBlueDarker,
    disabled: colors.lightBlueLight,
  },
  callToAction: {
    default: colors.orange,
    hover: colors.orangeDark,
    pressed: colors.orangeDarker,
    disabled: colors.orangeLight,
  },

};

const Button = styled.button`
  background-color: ${({type}) => buttonColors[type || 'primary'].default};
  color: white;
  padding: 6px 12px;
  font-size: 16px;
  border-radius: 2px;
  outline: none;
  border: none;
  width: auto;
  cursor: pointer;
  &:hover {
    background-color: ${({type}) => buttonColors[type || 'primary'].hover};
  }
  &:active {
    background-color: ${({type}) => buttonColors[type || 'primary'].pressed};
  }
  &:disabled {
    cursor: default;
    background-color: ${({type}) => buttonColors[type || 'primary'].disabled};
    &:hover {
      background-color: ${({type}) => buttonColors[type || 'primary'].disabled};
    }
  }
`;

export const ArrowButton = styled(Button)`
  padding-left: 48px;
  background-image: url(${Arrow});
  background-size: 24px 24px;
  background-repeat: no-repeat;
  background-position: 7px 7px;
`;

export default Button;

