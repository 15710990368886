import styled from 'styled-components';
import {devices} from '../../styles/theme';

export const H1 = styled.h1`
  margin: 0;
  font-size: 32px;
  line-height: 1.25;
  font-weight: bold;
  @media ${devices.tabletLandscape} {
    font-size: 40px;
    line-height: 1.125;
  }
  @media ${devices.desktopL} {
    font-size: 46px;
    line-height: 1.05;
  }
  @media ${devices.desktopXL} {
    font-size: 52px;
  }
`;

export const H2 = styled.h2`
  margin: 0;
  font-size: 26px;
  line-height: 1.25;
  font-weight: bold;
  @media ${devices.tabletLandscape}{
    font-size: 32px;
  }
  @media ${devices.desktop}{
    font-size: 34px;
  }
  @media ${devices.desktopXL}{
    font-size: 42px;
    line-height: 1.15;
  }
`;

export const H3 = styled.h3`
  margin: 0;
  font-size: 22px;
  line-height: 1.13;
  font-weight: bold;
  @media ${devices.tabletLandscape}{
    font-size: 26px;
    line-height: 1.25;
  }
  @media ${devices.desktop}{
    font-size: 32px;
  }
  @media ${devices.desktopXL}{
    font-size: 40px;
    line-height: 1.05;
  }
`;

export const H4 = styled.h4`
  margin: 0;
  font-size: 22px;
  line-height: 1.13;
  @media ${devices.tabletLandscape}{
    font-size: 26px;
    line-height: 1.25;
  }
  @media ${devices.desktop}{
    font-size: 32px;
  }
  @media ${devices.desktopXL}{
    font-size: 40px;
    line-height: 1.05;
  }
`;

export const H5 = styled.h5`
  margin: 0;
  font-size: 20px;
  font-weight: normal;
  line-height: 1.1;
  @media ${devices.tabletLandscape}{
    font-size: 24px;
  }
  @media ${devices.desktop}{
    font-size: 30px;
  }
  @media ${devices.desktopXL}{
    font-size: 32px;
    line-height: 1;
  }
`;

export const H6 = styled.h6`
  margin: 0;
  font-weight: normal;
  font-size: 18px;
  line-height: 1.25;
  @media ${devices.tabletLandscape}{
    font-size: 20px;
  }
  @media ${devices.desktop}{
    font-size: 24px;
  }
`;

export const Body = styled.p`
  margin: 0;
  font-size: 16px;
  line-height: 1.5;
  @media ${devices.desktop} {
    font-size: 18px;
  }
`;

export const Subtitle = styled.p`
  margin: 0;
  font-size: 13px;
  line-height: 1.5;
  @media ${devices.tabletLandscape} {
    font-size: 14px;
  }
  @media ${devices.desktop} {
    font-size: 15px;
  }
  @media ${devices.desktopXL} {
    font-size: 16px;
  }
`;
