import React from 'react';
import styled from 'styled-components';
import {GridComponentContainer, Media, TextContainer, Wave, RichText} from '../../atoms';
import {devices, colors, gridMaxWidth} from '../../../styles/theme';
import useResize from '../../../hooks/useResize';
import {getRefDimensions, getWindowDimensions} from '../../../utils/dimensions';

const HeaderContainer = styled(GridComponentContainer)`
  display: grid;
  grid-auto-rows: minmax(min-content, max-content);
  padding: 16px 0 4px;
  grid-template-columns: repeat(4, 1fr);
  @media ${devices.tabletPortrait} {
    padding: 24px 0 8px;
  }
  @media ${devices.tabletLandscape} {
    grid-template-columns: repeat(8, 1fr);
    padding: 32px 0 12px;
  }
  @media ${devices.desktop} {
    padding: 40px 0 16px;
  }
`;

const Tag = styled(RichText)`
  & > p {
    margin-bottom: 8px;
    font-size: 16px;
    line-height: 24px;
    color: ${colors.lightBlue};
    text-transform: uppercase;
    font-weight: bold;
  }
`;

const Title = styled(RichText)`
  & > p {
    margin: 0;
    font-size: 32px;
    line-height: 1.25;
    font-weight: bold;
    @media ${devices.tabletLandscape} {
      font-size: 40px;
      line-height: 1.125;
    }
    @media ${devices.desktopL} {
      font-size: 46px;
      line-height: 1.05;
    }
    @media ${devices.desktopXL} {
      font-size: 52px;
    }
    color: ${colors.blue};
  }
`;

const TitleContainer = styled(TextContainer)`
  grid-column: 1/5;
  margin-bottom: 24px;
  @media ${devices.tabletLandscape} {
    grid-column: 2/7;
  }
`;

const ImageContainer = styled.div`
  grid-column: 2/5;
  position: relative;
  @media ${devices.tabletLandscape} {
    grid-column: 3/9;
  }
`;

const WaveContainer = styled.div`
  position: absolute;
  left: ${({waveoffset}) => `-${waveoffset}px`};
  width: ${({wavelength}) => `${wavelength}px`};
  height: 96px;
  bottom: 15%;
  @media ${devices.tabletPortrait}{
    bottom: 20%;
  }
`;

const HeaderWave = styled(Wave)`
`;

const Header = ({header}) => {
  const {
    tag,
    text,
    media,
    showWave,
    waveColor,
    appearAnimation,
    variant
  } = header || {};
  const headerRef = React.useRef();
  const imageRef = React.useRef();
  const [headerWidth, setHeaderWidth] = React.useState(0);
  const [imageWidth, setImageWidth] = React.useState(0);
  const [windowWidth, setWindowWidth] = React.useState(0);
  const resizeTrigger = useResize();
  React.useEffect(() => {
    setHeaderWidth(getRefDimensions(headerRef).width);
    setImageWidth(getRefDimensions(imageRef).width);
    setWindowWidth(getWindowDimensions().width);
  }, [resizeTrigger]);
  // Used to calculate the dimensions of the wave element
  // in order for it to stretch full width and outside the grid
  const waveLength = Math.max(
    headerWidth / 2,
    (windowWidth - gridMaxWidth) / 2 + (headerWidth / 2)
  );
  const waveOffset = Math.max(
    headerWidth / 4,
    (windowWidth - gridMaxWidth) / 2 + (headerWidth / 4)
  );
  return (
    <HeaderContainer ref={headerRef} appearFrom={appearAnimation?.appearFrom}>
      <TitleContainer>
        <Tag source={tag} />
        <Title source={text} />
      </TitleContainer>
      <ImageContainer
        ref={imageRef}
      >
        <Media
          mediaWidth={imageWidth}
          aspectRatio={variant === 'XL' ? 16 / 9 : 3.2}
          media={media}
        />
        {showWave && (
          <WaveContainer
            wavelength={waveLength}
            waveoffset={waveOffset}
          >
            <HeaderWave
              waveColor={waveColor}
              waveLength={waveLength}
            />
          </WaveContainer>
        )}
      </ImageContainer>
    </HeaderContainer>
  );
};

export default Header;

