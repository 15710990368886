import React from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize from 'rehype-sanitize';

const Markdown = props => (
  <ReactMarkdown rehypePlugins={[rehypeRaw, rehypeSanitize]} {...props} />
);

export default Markdown;
