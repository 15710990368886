import {css} from 'styled-components';
import {devices} from './theme';

const translationDistance = {
  mobile: 40,
  tablet: 70,
  desktop: 100
};

const appearFromBottom = (className, duration) => css`
  opacity: 0;
  transition: all ${duration}ms ease;
  transform: translateY(${translationDistance.mobile}px);
  @media ${devices.tabletPortrait} {
    transform: translateY(${translationDistance.tablet}px);
  }
  @media ${devices.desktopL} {
    transform: translateY(${translationDistance.desktop}px);
  }
  &.${className}-enter {
    opacity: 0;
    transform: translateY(${translationDistance.mobile}px);
    @media ${devices.tabletPortrait} {
      transform: translateY(${translationDistance.tablet}px);
    }
    @media ${devices.desktopL} {
      transform: translateY(${translationDistance.desktop}px);
    }
  }
  &.${className}-enter-active {
    opacity: 1;
    transform: translateY(0);
  }
  &.${className}-enter-done {
    opacity: 1;
    transform: translateY(0);
  }
`;

const appearFromTop = (className, duration) => css`
  opacity: 0;
  transition: all ${duration}ms ease;
  transform: translateY(${-translationDistance.mobile}px);
  @media ${devices.tabletPortrait} {
    transform: translateY(${-translationDistance.tablet}px);
  }
  @media ${devices.desktopL} {
    transform: translateY(${-translationDistance.desktop}px);
  }
  &.${className}-enter {
    opacity: 0;
    transform: translateY(${-translationDistance.mobile}px);
    @media ${devices.tabletPortrait} {
      transform: translateY(${-translationDistance.tablet}px);
    }
    @media ${devices.desktopL} {
      transform: translateY(${-translationDistance.desktop}px);
    }
  }
  &.${className}-enter-active {
    opacity: 1;
    transform: translateY(0);
  }
  &.${className}-enter-done {
    opacity: 1;
    transform: translateY(0);
  }
`;

const appearFromLeft = (className, duration) => css`
  opacity: 0;
  transition: all ${duration}ms ease;
  transform: translateX(${-translationDistance.mobile}px);
  @media ${devices.tabletPortrait} {
    transform: translateX(${-translationDistance.tablet}px);
  }
  @media ${devices.desktopL} {
    transform: translateX(${-translationDistance.desktop}px);
  }
  &.${className}-enter {
    opacity: 0;
    transform: translateX(${-translationDistance.mobile}px);
    @media ${devices.tabletPortrait} {
      transform: translateX(${-translationDistance.tablet}px);
    }
    @media ${devices.desktopL} {
      transform: translateX(${-translationDistance.desktop}px);
    }
  }
  &.${className}-enter-active {
    opacity: 1;
    transform: translateX(0);
  }
  &.${className}-enter-done {
    opacity: 1;
    transform: translateX(0);
  }
`;

const appearFromRight = (className, duration) => css`
  opacity: 0;
  transition: all ${duration}ms ease;
  transform: translateX(${translationDistance.mobile}px);
  @media ${devices.tabletPortrait} {
    transform: translateX(${translationDistance.tablet}px);
  }
  @media ${devices.desktopL} {
    transform: translateX(${translationDistance.desktop}px);
  }
  &.${className}-enter {
    opacity: 0;
    transform: translateX(${translationDistance.mobile}px);
    @media ${devices.tabletPortrait} {
      transform: translateX(${translationDistance.tablet}px);
    }
    @media ${devices.desktopL} {
      transform: translateX(${translationDistance.desktop}px);
    }
  }
  &.${className}-enter-active {
    opacity: 1;
    transform: translateX(0);
  }
  &.${className}-enter-done {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const appear = {
  left: appearFromLeft,
  right: appearFromRight,
  bottom: appearFromBottom,
  top: appearFromTop
};
