import React from 'react';
import styled from 'styled-components';
import {colors, devices} from '../../styles/theme';
import {
  Button,
  GridComponentContainer,
  H2,
  RichText,
  Subtitle,
  TextContainer,
  Link
} from '../atoms';

const Container = styled(GridComponentContainer)`
  display: flex;
  flex-direction: column;
  ${TextContainer} {
    ${H2} {
      color: ${colors.blue};
      margin-bottom: 8px;
    }
  }
  @media ${devices.tabletLandscape}{
    ${TextContainer} {
      width: 50%;
      ${H2} {
        margin-bottom: 16px;
      }
    }
  }
`;

const SectionTitle = styled(RichText)`
  & > p {
    margin-bottom: 4px;
    font-size: 14px;
    line-height: 24px;
    color: ${colors.lightBlue};
    text-transform: uppercase;
    font-weight: bold;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${TextContainer} {
    flex: 1;
  }
  @media ${devices.tabletLandscape}{
    flex-direction: row;
  }
`;

const SpecificationContainer = styled.div`
  display: flex;
  border-bottom: 2px solid ${colors.lightGrey};
  padding: 8px 0;
  &:last-child {
    border-bottom: none;
  }
`;

const SpecTitle = styled(Subtitle)`
  color: black;
  font-weight: bold;
  flex: 0.4;
  display: flex;
  align-items: center;
  margin-right: 24px;
  `;

const SpecValue = styled(Subtitle)`
  color: ${colors.xanadu};
  flex: 0.6;
  display: flex;
  align-items: center;
`;

const SystemDescription = styled(TextContainer)`
  margin-bottom: 16px;
  & > a {
    display: inline-block;
    margin-top: 16px;
  }
  @media ${devices.tabletLandscape}{
    margin-bottom: 0;
  }
`;

const Specification = ({title, value}) => (
  <SpecificationContainer>
    <SpecTitle>{title}</SpecTitle>
    <SpecValue>{value}</SpecValue>
  </SpecificationContainer>
);

const SystemInfo = ({
  title,
  infoTitle,
  specificationsTitle,
  button,
  description,
  specifications
}) => (
  <Container fromColumn={2} widthInColumns={6}>
    <TextContainer>
      <H2>{title}</H2>
    </TextContainer>
    <InfoContainer>
      <SystemDescription>
        <SectionTitle source={infoTitle}/>
        <RichText source={description} />
        {button && (
          <Link to={button.link}>
            <Button type={button.type}>
              {button.text}
            </Button>
          </Link>
        )}
      </SystemDescription>
      <TextContainer>
        <SectionTitle source={specificationsTitle} />
        {specifications.map(s => <Specification key={s.title} title={s.title} value={s.value} />)}
      </TextContainer>
    </InfoContainer>
  </Container>
);

export default SystemInfo;
