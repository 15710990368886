/* eslint-disable max-len */
import React from 'react';
import {colors} from '../../styles/theme';

const Wave = ({waveColor, waveLength}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={waveLength}
    height="100%"
  >
    <defs>
      <pattern id={`wave-${waveColor}`} x="0" y="0" width="745" height="100%" patternUnits="userSpaceOnUse">
        <g fill="none" stroke="none" opacity="0.3">
          <g fill={colors[waveColor]} transform="translate(-1 -672)">
            <g transform="translate(.5 672)">
              <path
                d="M559.25 0c-50.288 0-81.95 12.322-109.887 22.925C425.15 32.955 406.524 40.12 373 40.12s-52.15-7.164-76.363-17.194C268.7 12.035 238.155 0 186.75 0S104.8 12.322 76.862 22.925C52.65 32.955 34.025 40.12.5 40.12V96c50.288 0 81.95-12.322 109.888-22.925 24.212-9.17 43.954-17.194 76.362-17.194 32.407 0 52.15 7.164 76.363 17.194C291.05 83.965 321.595 96 373 96s81.95-12.322 109.887-22.925c24.213-9.17 43.955-17.194 76.363-17.194 33.525 0 52.15 7.164 76.362 17.194C663.55 83.965 694.468 96 745.5 96V40.12c-33.525 0-52.15-7.165-76.362-17.195C641.2 12.035 609.538 0 559.25 0z"
                transform="matrix(1 0 0 -1 0 96)"
              ></path>
            </g>
          </g>
        </g>
      </pattern>
    </defs>
    <rect x="0" y="0" width="100%" height="100%" fill={`url(#wave-${waveColor})`}></rect>
  </svg>
);

export default Wave;
