import React from 'react';
import styled, {css} from 'styled-components';
import BulletPoint from '../../assets/images/bullet-point.svg';
import {colors, devices} from '../../styles/theme';
// eslint-disable-next-line atomic-design/hierarchical-import
import Markdown from './Markdown';

export const richTextStyles = css`
  color: ${colors.xanadu};
  & p {
    margin: 0;
    font-size: 14px;
    @media ${devices.tabletLandscape} {
      font-size: 16px;
    }
    & a {
      color: ${colors.lightBlue};
      text-decoration: none;
      &:visited {
        color: ${colors.blue};
      }
    }
  }
  & ul {
    list-style: none;
    margin: 4px 0 0 20px;
    @media ${devices.tabletLandscape} {
      margin: 8px 0 0 24px;
    }
    & li {
      font-size: 14px;
      margin: 0 0 1px 0;
      @media ${devices.tabletLandscape} {
        font-size: 16px;
        margin: 0 0 2px 0;
      }
      position: relative;
      &::before {
        content: '';
        width: 10px;
        height: 10px;
        position: absolute;
        ${css`
          background-image: url(${BulletPoint});
        `}
        background-size: cover;
        background-position: center;
        left: -16px;
        top: 11px;
        transform: translateY(-50%);
        @media ${devices.tabletLandscape} {
          width: 12px;
          height: 12px;
          left: -20px;
          top: 13px;
          font-size: 16px;
        }
      }
    }
  }
  p, h1, h2, h3, h4, h5, h6, ul, ol {
    &:not(:last-child) {
      margin-bottom: 1.45rem;
    }
  }
  & > span:not(:last-child) {
    p, h1, h2, h3, h4, h5, h6, ul, ol {
      margin-bottom: 1.45rem;
    }
  }
`;

const RichTextBody = styled(Markdown)`
  ${richTextStyles}
`;

const RichText = ({source, className}) => {
  const richTextRef = React.useRef();
  React.useEffect(() => {
    const allLinks = richTextRef.current.getElementsByTagName('a');
    for (const link of allLinks) {
      link.setAttribute('target', '_blank');
    }
  }, []);
  return (
    <div ref={richTextRef}>
      <RichTextBody className={className}>
        {source}
      </RichTextBody>
    </div>
  );
};

export default RichText;
