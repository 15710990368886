import React from 'react';
import styled from 'styled-components';
import {colors, devices} from '../../styles/theme';
import {GridComponentContainer, H2, RichText, TextContainer} from '../atoms';

const Container = styled(GridComponentContainer)`
`;

const IntroContainer = styled(TextContainer)`
  ${H2} {
    margin-bottom: 8px;
    color: ${colors.blue};
  }
  & > div {
    color: ${colors.xanadu};
    font-size: 16px;
    font-weight: bold;
  }
  @media ${devices.tabletLandscape}{
    & > div {
      color: ${colors.xanadu};
      font-size: 18px;
      font-weight: bold;
    }
  }
`;

const Intro = ({intro}) => (
  <Container
    fromColumn={intro?.startingColumn}
    widthInColumns={intro?.widthInColumns}
    appearFrom={intro?.appearAnimation?.appearFrom}
  >
    <IntroContainer>
      {intro?.text?.title && <H2>{intro.text.title}</H2>}
      <RichText source={intro?.text?.body} />
    </IntroContainer>
  </Container>
);

export default Intro;

