/* eslint-disable complexity */

import React from 'react';
import * as Blocks from './blocks';

const Default = () => null;

const renderBlock = ({__typename, id, ...rest}, url, center) => {
  switch(__typename) {
    case 'STRAPI_ComponentBlocksCarousel':
      return <Blocks.Carousel key={`${__typename}-${id}`} url={url} {...rest} />;
    case 'STRAPI_ComponentBlocksContactUs':
      return <Blocks.ContactUs key={`${__typename}-${id}`} url={url} {...rest}/>;
    case 'STRAPI_ComponentBlocksContact':
      return <Blocks.ContactPerson key={`${__typename}-${id}`} url={url} {...rest}/>;
    case 'STRAPI_ComponentBlocksDownload':
      return <Blocks.Download key={`${__typename}-${id}`} url={url} {...rest}/>;
    case 'STRAPI_ComponentBlocksMediaWithText':
      return (
        <Blocks.MediaWithText
          key={`${__typename}-${id}`}
          url={url}
          {...rest}
          startingColumn={center ? 2 : rest.startingColumn}
        />
      );
    case 'STRAPI_ComponentBlocksMedia':
      return <Blocks.Media key={`${__typename}-${id}`} url={url} {...rest}/>;
    case 'STRAPI_ComponentBlocksPlainText':
      return (
        <Blocks.PlainText
          key={`${__typename}-${id}`}
          url={url}
          {...rest}
          startingColumn={center ? 3 : rest.startingColumn}
          widthInColumns={center ? 4 : rest.widthInColumns}
        />
      );
    case 'STRAPI_ComponentBlocksShare':
      return <Blocks.Share key={`${__typename}-${id}`} url={url} {...rest}/>;
    case 'STRAPI_ComponentBlocksTestimonial':
      return <Blocks.Testimonial key={`${__typename}-${id}`} url={url} {...rest}/>;
    case 'STRAPI_ComponentBlocksUspBlock':
      return <Blocks.USP key={`${__typename}-${id}`} url={url} {...rest}/>;
    default: return <Default key={`${__typename}-${id}`} />;
  }
};

const DynamicZone = ({
  blocks,
  url,
  center = false
}) => blocks.map(block => renderBlock(block, url, center));

export default DynamicZone;
