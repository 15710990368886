import React from 'react';
/* eslint-disable-next-line atomic-design/hierarchical-import */
import * as S from './styles';
import CloseIcon from '../../../images/svgsComponents/Close.svg';
import PhoneIcon from '../../../images/svgsComponents/Contact.svg';
import Chevron from '../../../images/svgsComponents/Chevron.svg';
import {colors, devices} from '../../../styles/theme';
import {Button, H2, H6, Link} from '../../atoms';
import AnimateHeight from 'react-animate-height';
import styled from 'styled-components';
import useNavigationData from '../../../queries/useNavigationData';
import {NavigationContext} from '../../../services/navigation';
import {CSSTransition} from 'react-transition-group';
import WhiteTextLogo from '../../../assets/images/whiteTextLogo.svg';

const ContactContainer = styled.div`
  padding: 4vh 16px;
  background: ${colors.orange};
  display: flex;
  @media ${devices.tabletLandscape} {
    padding: 16px;
  }
`;

const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const AnimatedMenu = styled(AnimateHeight)`
  padding: 0 20%;
  & > div > a:first-child > h5 {
    margin-top: 8px;
  }
  & > div > a:last-child > h5 {
    margin-bottom: 16px;
  }
`;

const GetInTouch = ({data, onButtonClick}) => (
  <ContactContainer>
    <S.LogoContainer style={{margin: '-4px 12px 0 0'}} color={colors.orange} inverted disabled>
      <PhoneIcon />
    </S.LogoContainer>
    <ContactInfo>
      <H6 style={{color: 'white', fontWeight: 'bold'}}>{data.title}</H6>
      <p style={{margin: '12px 0', color: 'white'}}>{data.subtitle}</p>
      {data.button && (
        <Link
          to={data.button.link}
          style={{textDecoration: 'none'}}
          onClick={onButtonClick}
        >
          <Button type={data.button.type}>{data.button.text}</Button>
        </Link>
      )}
    </ContactInfo>
  </ContactContainer>
);

const MobileMenu = () => {
  const {routes, contact} = useNavigationData();
  const {
    isMenuOpen,
    setIsMenuOpen,
    setIsMenuExpanded,
    hoveredMenuRoute,
    setHoveredMenuRoute,
  } = React.useContext(NavigationContext);
  return(
    <CSSTransition in={isMenuOpen} timeout={400} classNames="open">
      <S.MobileMenuContainer>
        <S.TopContainer>
          <S.CloseIconContainer onClick={() => setIsMenuOpen(false)}>
            <CloseIcon />
          </S.CloseIconContainer>
          <S.MenuHomeLink
            to="/"
            onClick={() => {
              setHoveredMenuRoute(null);
              setIsMenuExpanded(false);
              setIsMenuOpen(false);
            }}
          >
            <S.AmpelmannLogo src={WhiteTextLogo}/>
          </S.MenuHomeLink>
        </S.TopContainer>
        <S.NavigationContainer>
          <S.MainRoutes>
            {routes.main.map((mainRoute, index) =>
              mainRoute.isDeep
                ? (
                  <div key={mainRoute.name}>
                    <S.MobileDeepRouteLink
                      hovered={hoveredMenuRoute === index + 1}
                      onClick={() => {
                        setHoveredMenuRoute((hoveredMenuRoute === index + 1) ? null : index + 1);
                        setIsMenuExpanded(true);
                      }}
                    >
                      <S.DeepMainRouteName hovered={hoveredMenuRoute === index + 1}>
                        {mainRoute.name}
                      </S.DeepMainRouteName>
                      <Chevron
                        style={{
                          width: 24,
                          height: 24,
                          transition: 'all 150ms',
                          transform: `rotate(${hoveredMenuRoute === index + 1 ? 90 : 0}deg)`
                        }}
                      />
                    </S.MobileDeepRouteLink>
                    <AnimatedMenu
                      id={mainRoute.name}
                      duration={150}
                      height={hoveredMenuRoute === index + 1
                        ? 'auto'
                        : 0
                      }
                    >
                      {routes.main[index]?.subRoutes.map(subRoute => (
                        <Link
                          key={subRoute.name}
                          to={subRoute.to} style={{textDecoration: 'none', color: 'white'}}
                          onClick={() => {
                            setHoveredMenuRoute(null);
                            setIsMenuExpanded(false);
                            setIsMenuOpen(false);
                          }}
                        >
                          <S.DeepLink>
                            <H6>{subRoute.name}</H6>
                          </S.DeepLink>
                        </Link>
                      ))}
                    </AnimatedMenu>
                  </div>
                ) : (
                  <S.MobileRouteName
                    key={mainRoute.name}
                    to={mainRoute.to}
                    style={{textDecoration: 'none'}}
                    onClick={() => setIsMenuOpen(false)}
                  >
                    <H2>{mainRoute.name}</H2>
                  </S.MobileRouteName>
                )
            )}
          </S.MainRoutes>
          <S.SecondaryRoutes>
            {routes.secondary.map(secondaryRoute => (
              <S.MobileRouteName
                key={secondaryRoute.name}
                to={secondaryRoute.to}
                style={{textDecoration: 'none'}}
                onClick={() => setIsMenuOpen(false)}
              >
                <H6>{secondaryRoute.name}</H6>
              </S.MobileRouteName>
            ))}
          </S.SecondaryRoutes>
          <S.OtherRoutes>
            {routes.rest.map(secondaryRoute => (
              <S.MobileRouteName
                key={secondaryRoute.name}
                to={secondaryRoute.to}
                style={{textDecoration: 'none'}}
                onClick={() => setIsMenuOpen(false)}
              >
                <H6>{secondaryRoute.name}</H6>
              </S.MobileRouteName>
            ))}
          </S.OtherRoutes>
        </S.NavigationContainer>
        {contact && (
          <GetInTouch
            data={contact}
            onButtonClick={() => {
              setIsMenuExpanded(false);
              setIsMenuOpen(false);
            }}
          />
        )}
      </S.MobileMenuContainer>
    </CSSTransition>
  );
};

export default MobileMenu;
