import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import styled from 'styled-components';
import {
  SocialIcon,
} from 'react-social-icons';

import {colors, devices, gridMaxWidth} from '../../styles/theme';
import TextLogo from '../../assets/images/textLogo.svg';
import {hex2rgbaTrue} from '../../utils/hex2rgb';
import {getWindowDimensions} from '../../utils/dimensions';
import {FooterLink, Link} from '../atoms';

const Container = styled.div`
  background: ${hex2rgbaTrue(colors.lightGrey)};
  width: 100%;
  display: flex;
  justify-content: center;
`;

const ContentContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: minmax(min-content, max-content);
  flex: 1;
  max-width: ${gridMaxWidth}px;
  padding: 16px 0;
  @media ${devices.tabletPortrait} {
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: 1fr 1fr;
    padding: 20px 0;
  }
  @media ${devices.tabletLandscape} {
    padding: 24px 0;
  }
  `;

const SocialLinksContainer = styled.div`
  grid-column: 1 / 2;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${devices.tabletPortrait} {
    flex-direction: row;
    grid-column: 3 / 1;
    grid-row: 2 / 3;
    margin-left: auto;
  }
`;

const Logo = styled.img`
  ${({windowwidth}) => `
    width: ${0.75 * windowwidth}px;
    height: ${0.75 * windowwidth / 4.85}px;
  `}
  background: ${colors.blue};
  @media ${devices.tabletPortrait} {
    ${({windowwidth}) => `
      width: ${0.25 * windowwidth}px;
      height: ${0.25 * windowwidth / 4.85}px;
    `}
  }
  @media ${devices.tabletLandscape} {
    margin-left: auto;
    grid-column: 1 / 3;
    grid-row: 1 / 2;
    width: 200px;
    height: 42px;
  }
  @media ${devices.desktop} {
    width: 240px;
    height: 56px;
  }
  @media ${devices.desktopXL} {
    width: 272px;
    height: 56px;
  }
  `;

const LinksContainer = styled.div`
  grid-column: 2 / 5;
  display: flex;
  flex-direction: column;
  @media ${devices.tabletPortrait} {
    grid-column: 5 / 9;
    grid-row: 1 / 3;
  }
  @media ${devices.tabletLandscape} {
    grid-column: 6 / 8;
  }
`;

const CookieLink = styled.a`
  font-size: 15px;
  margin-bottom: 4px;
  text-decoration: none;
  color: ${colors.grey};
  cursor: pointer;
  @media ${devices.tabletPortrait} {
    margin: 0;
  }
  @media ${devices.desktop} {
    font-size: 14px;
  }
`;

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      strapi {
        footer {
          links {
            ...Link
          }
          socialLinks {
            url
            platform
          }
        }
      }
    }
  `);
  const {strapi: {footer}} = data;
  const {links, socialLinks} = footer ?? {};
  const windowWidth = getWindowDimensions().width;
  return (
    <Container>
      <ContentContainer>
        <Link to="/" style={{gridColumn: '1 / 4'}}>
          <Logo src={TextLogo} windowwidth={windowWidth}/>
        </Link>
        <SocialLinksContainer>
          {socialLinks && socialLinks.map(({url, platform}) =>
            <SocialIcon
              key={platform}
              url={url}
              target="blank"
              network={platform.toLowerCase()}
              fgColor={colors.grey}
              bgColor="none"
            />
          )}
        </SocialLinksContainer>
        <LinksContainer windowwidth={windowWidth}>
          {links && links.map(link => (
            <FooterLink
              style={{marginBottom: 8}}
              to={link.URL}
              key={link.text}
            >
              {link.text}
            </FooterLink>
          ))}
          <CookieLink onClick={() => {
            if (typeof window.Cookiebot?.renew === 'function') {
              window.Cookiebot.renew();
            }
          }}>Cookie statement</CookieLink>
        </LinksContainer>
      </ContentContainer>
    </Container>
  );
};

export default Footer;

