import React from 'react';
import styled from 'styled-components';
import {
  H4,
  RichText,
  Button,
  GridComponentContainer,
  Media,
  TextContainer,
  Link
} from '../../atoms';
import {colors, devices} from '../../../styles/theme';
import useResize from '../../../hooks/useResize';
import {getRefDimensions} from '../../../utils/dimensions';

const getTextColumns = mediaPosition =>
  mediaPosition === 'left'
    ? '5 / 7'
    : mediaPosition === 'right'
      ? '1 / 3'
      : '2 / 6';

const getMediaColumns = mediaPosition =>
  mediaPosition === 'left'
    ? '1 / 5'
    : mediaPosition === 'right'
      ? '3 / 7'
      : '2 / 6';

const TextComponentContainer = styled(TextContainer)`
  grid-column: 1 / 5;
  margin: 16px 0;
  grid-row: ${({mediaposition}) => mediaposition === 'bottom' ? '1/2' : 'auto'};
  ${H4} {
    margin-bottom: 4px;
    color: ${colors.blue};
  }
  & > div {
    margin-bottom: 8px;
  }
  @media ${devices.tabletPortrait}{
    ${H4} {
      margin-bottom: 8px;
    }
    & > div {
      margin-bottom: 12px;
    }
  }
  @media ${devices.tabletLandscape}{
    margin: 20px 0;
    padding: 0 20px;
    grid-column: ${({mediaposition, fullwidth}) =>
    fullwidth ? '1/7' : getTextColumns(mediaposition)};
  }
  @media ${devices.desktop}{
    margin: 24px 0;
    padding: 0 24px;
    ${H4} {
      margin-bottom: 12px;
    }
    & > div {
      margin-bottom: 16px;
    }
  }
  @media ${devices.desktopXL}{
    ${H4} {
      margin-bottom: 16px;
    }
    & > div {
      margin-bottom: 16px;
    }
  }
`;

const MediaComponentContainer = styled.div`
  grid-column: 1 / 5;
  grid-row: ${({mediaposition}) => mediaposition === 'bottom' ? '2/3' : 'auto'};
  @media ${devices.tabletLandscape}{
    grid-column: ${({mediaposition, fullwidth}) =>
    fullwidth ? '1/7' : getMediaColumns(mediaposition)};
  }
`;

const Container = styled(GridComponentContainer)`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(4, 1fr);
  @media ${devices.tabletLandscape} {
    grid-template-columns: repeat(6, 1fr);
  }
`;

const MediaWithText = ({
  mediaComponent,
  mediaPosition,
  textComponent:
    {
      title,
      body
    },
  button,
  startingColumn,
  appearAnimation,
  className,
  fullWidth = false,
  truncate = false
}) => {
  const mediaRef = React.useRef();
  const textRef = React.useRef();
  const resizeTrigger = useResize();
  const [mediaWidth, setMediaWidth] = React.useState(0);
  const [mediaHeight, setMediaHeight] = React.useState(0);
  React.useEffect(() => {
    setMediaWidth(getRefDimensions(mediaRef).width);
    setMediaHeight(getRefDimensions(textRef).height);
  }, [resizeTrigger]);
  return (
    <Container
      fromColumn={startingColumn}
      widthInColumns={6}
      appearFrom={appearAnimation?.appearFrom}
      className={className}
    >
      <MediaComponentContainer
        ref={mediaRef}
        mediaposition={mediaPosition}
        fullwidth={fullWidth}
      >
        <Media
          media={mediaComponent}
          mediaWidth={mediaWidth}
          // 24 is the total vertical margin of the text container
          height={mediaHeight + 24}
        />
      </MediaComponentContainer>
      <TextComponentContainer
        ref={textRef}
        mediaposition={mediaPosition}
        fullwidth={fullWidth}
      >
        {title && <H4>{title}</H4>}
        <RichText source={body} truncate={truncate}/>
        {button && (
          <Link to={button.link}>
            <Button type={button.type}>
              {button.text}
            </Button>
          </Link>
        )}
      </TextComponentContainer>
    </Container>
  );
};

export default MediaWithText;

