import React from 'react';
import styled from 'styled-components';
import {GridComponentContainer, TextContainer, H2} from '../../atoms';
import {colors} from '../../../styles/theme';
import slugify from 'slugify';

const DownloadTitle = styled(H2)`
  color: ${colors.blue};
  margin-bottom: 16px;
`;

const DownloadContainer = styled(GridComponentContainer)``;

const DownloadLink = styled.a`
  color: ${colors.lightBlue};
`;

const LinkList = styled.ul`
  padding-left: 24px;
`;

const FileLink = ({displayName, downloadName, file, isDownload}) => {
  const linkRef = React.useRef();
  React.useEffect(() => {
    if (isDownload) {
      linkRef.current.setAttribute(
        'download',
        slugify(downloadName || displayName || '', {
          replacement: '_',
          lower: true
        })
      );
    }
  }, []);
  return (
    <li>
      <DownloadLink
        ref={linkRef}
        target="_blank"
        href={file.file.publicURL}
      >
        {displayName}
      </DownloadLink>
    </li>
  );
};

const Download = ({mainTitle, files, startingColumn = 2, appearAnimation}) => (
  <DownloadContainer
    appearFrom={appearAnimation?.appearFrom}
    fromColumn={startingColumn}
  >
    <TextContainer>
      {mainTitle && <DownloadTitle>{mainTitle}</DownloadTitle>}
      <LinkList>
        {files.map(f => (
          <FileLink
            key={f.id}
            file={f.file}
            displayName={f.displayName}
            downloadName={f.downloadName}
            isDownload={f.interaction === 'download'}
          />
        ))}
      </LinkList>
    </TextContainer>
  </DownloadContainer>
);

export default Download;
