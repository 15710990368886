/* eslint-disable atomic-design/hierarchical-import */
import React from 'react';
import {CSSTransition} from 'react-transition-group';
import styled from 'styled-components';
import TextLogo from '../../../assets/images/textLogo.svg';
import LogoShort from '../../../images/logo.svg';
import ContactIcon from '../../../images/svgsComponents/Contact.svg';
import HamburgerIcon from '../../../images/svgsComponents/Hamburger.svg';
import InsightsIcon from '../../../images/svgsComponents/Person.svg';
import SearchIcon from '../../../images/svgsComponents/Search.svg';
import {NavigationContext} from '../../../services/navigation';
import {colors, devices} from '../../../styles/theme';
import {hex2rgba} from '../../../utils/hex2rgb';
import {Link} from '../../atoms';
import {AmpelmannLogo, AmpelmannLogoShort, CareersButton, LogoContainer} from './styles';

const TopBarContainer = styled.div`
  transform: translateY(${({hide}) => hide ? '-100%' : '0%'});
  opacity: ${({hide}) => hide ? '0' : '1'};
  background: ${({hide}) => hide ? 'transparent' : 'rgba(255, 255, 255, 0.8)'};
  box-shadow: ${({hide}) => hide ? 'none' : `0px 0px 8px 3px ${hex2rgba(colors.grey, 0.2)}`};
  transition: all 500ms;
  position: fixed;
  z-index: 5;
  padding: 8px;
  width: 100%;
  display: flex;
  align-items: center;
  @media ${devices.tabletLandscape} {
    padding-right: 0;
  }
  @media ${devices.desktop} {
    padding: 12px 0 12px 12px;
  }
  @media ${devices.desktop} {
    padding: 16px 0 16px 16px;
  }
`;

const WavesContainer = styled(LogoContainer)`
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  @media ${devices.tabletLandscape} {
    margin-right: 12px;
    border-radius: 4px;
  }
  @media ${devices.desktop} {
    margin-right: 16px;
    border-radius: 4px;
  }
  @media ${devices.desktopXL} {
    margin-right: 24px;
  }
`;

const InsightsIconContainer = styled(LogoContainer)`
  display: none;
  @media ${devices.tabletPortrait} {
    display: flex;
  }
`;

const OtherLogosContainer = styled.div`
  position: absolute;
  right: 0;
  top: 8px;
  z-index: 2;
  display: flex;
  @media ${devices.tabletLandscape} {
    position: unset;
    margin-left: auto;
  }
`;

const HomeLink = styled(Link)`
  height: 44px;
  position: relative;
  @media ${devices.desktop} {
    height: 56px;
  }
`;

const ShrinkableAmpelmannLogo = styled(AmpelmannLogo)`
    height: 44px;
    width: 212px;
    transition: transform 400ms ease-out;
    transform-origin: left;
    @media ${devices.desktop} {
      height: 56px;
      width: 272px;
    }
    &.shrink-enter {
      transform: scaleX(0);
    }
    &.shrink-enter-active {
      transform: scaleX(1);
    }
    &.shrink-enter-done {
      transform: scaleX(1);
    }
    &.shrink-exit {
      transform: scaleX(1);
    }
    &.shrink-exit-active {
      transform: scaleX(0);
    }
    &.shrink-exit-done {
      transform: scaleX(0);
    }
  `;

const SearchLogoContainer = styled(LogoContainer)`
  transition: transform 500ms;
`;

const TopBar = (_, ref) => {
  const {
    setIsMenuOpen,
    setIsMenuExpanded,
    setIsSearchActive,
    isSearchActive,
    hideTopBar,
    location
  } = React.useContext(NavigationContext);
  return (
    <TopBarContainer ref={ref} hide={hideTopBar}>
      <WavesContainer color={colors.blue} onClick={() => setIsMenuOpen(true)}>
        <HamburgerIcon />
      </WavesContainer>
      <HomeLink to="/">
        <CSSTransition in={location === '/'} timeout={400} classNames="shrink">
          <ShrinkableAmpelmannLogo src={TextLogo} />
        </CSSTransition>
        <AmpelmannLogoShort src={LogoShort} style={{position: 'absolute', top: 0, left: 0}}/>
      </HomeLink>
      <OtherLogosContainer>
        <Link style={{textDecoration: 'none'}} to="https://careers.ampelmann.nl">
          <CareersButton>
            Careers
          </CareersButton>
        </Link>
        <InsightsIconContainer color={colors.orange} inverted>
          <Link
            to="https://insights.ampelmann.nl/"
            onClick={() => {
              setIsMenuOpen(false);
              setIsMenuExpanded(false);
            }}
          >
            <InsightsIcon />
          </Link>
        </InsightsIconContainer>
        <LogoContainer color={colors.orange} inverted>
          <Link
            to="/contact"
            onClick={() => {
              setIsMenuOpen(false);
              setIsMenuExpanded(false);
            }}
          >
            <ContactIcon />
          </Link>
        </LogoContainer>
        {location !== '/zoeken' && <SearchLogoContainer
          color={colors.orange}
          inverted
          onClick={() => {
            setIsMenuOpen(false);
            setIsMenuExpanded(false);
            setIsSearchActive(true);
          }}
          style={{
            transform: `translateX(${isSearchActive ? 100 : 0}%)`
          }}
        >
          <SearchIcon />
        </SearchLogoContainer>}
      </OtherLogosContainer>
    </TopBarContainer>
  );
};

export default React.forwardRef(TopBar);
