export const breakpoints = {
  tabletPortrait: 600,
  tabletLandscape: 900,
  desktop: 1200,
  desktopL: 1440,
  desktopXL: 1600
};

export const mediaQuery = (minWidth, maxWidth) => {
  const hasMin = typeof minWidth !== 'undefined';
  const hasMax = typeof maxWidth !== 'undefined';
  return `${
    hasMin ? `(min-width: ${minWidth}px)` : ''
  }${
    hasMin && hasMax ? ' and ' : ''
  }${
    hasMax ? `(max-width: ${maxWidth}px)` : ''
  }`;
};

export const devices = {
  tabletPortrait: mediaQuery(breakpoints.tabletPortrait),
  tabletLandscape: mediaQuery(breakpoints.tabletLandscape),
  desktop: mediaQuery(breakpoints.desktop),
  desktopL: mediaQuery(breakpoints.desktopL),
  desktopXL: mediaQuery(breakpoints.desktopXL),
  mobileOnly: mediaQuery(undefined, breakpoints.tabletPortrait - 1),
  tabletPortraitOnly: mediaQuery(breakpoints.tabletPortrait, breakpoints.tabletLandscape - 1),
  tabletLandscapeOnly: mediaQuery(breakpoints.tabletLandscape, breakpoints.desktop - 1),
  desktopOnly: mediaQuery(breakpoints.desktop, breakpoints.desktopL - 1),
  desktopLOnly: mediaQuery(breakpoints.desktopL, breakpoints.desktopXL - 1),
};

export const colors = {
  blueLight: '#8891B4',
  blue: '#112369',
  blueDark: '#071856',
  blueDarker: '#06154A',
  deepBlue: '#0D1A4F',
  lightBlueLight: '#B2E8F4',
  lightBlue: '#65D1E8',
  lightBlueDark: '#48B3CA',
  lightBlueDarker: '#43A7BD',
  orangeLight: '#FDCF7F',
  orange: '#FB9F00',
  orangeDark: '#E08E00',
  orangeDarker: '#D48600',
  rose: '#CA2E55',
  green: '#329278',
  darkGrey: '#4D5158',
  grey: '#777E80',
  lightGrey: '#EDF1F3',
  xanadu: '#647472'
};

export const gridMaxWidth = 1600;
