import React from 'react';
import styled from 'styled-components';
import {CSSTransition} from 'react-transition-group';
import {colors} from '../../styles/theme';

const Container = styled.div`
  margin-bottom: 24px;
  position: relative;
  &:last-child {
    margin-bottom: 0;
  }
`;

const Input = styled.input`
  height: 40px;
  width: 100%;
  color: ${colors.xanadu};
  outline: none;
  font-weight: normal;
  border: solid 1px rgba(77,81,88,0.3);
  border-radius: 2px;
  padding: 0 12px;
  background: ${({hasError}) => hasError ? `${colors.rose}30` : 'transparent'};
  ${({hasError}) => hasError && `border-color: ${colors.rose}`};
  &:focus {
    border-color: ${colors.lightBlue};
    background: ${`${colors.lightBlue}30`};
  }
  `;

const MultiLine = styled.textarea`
  height: 129px;
  padding: 6px 12px;
  font-weight: normal;
  resize: none;
  width: 100%;
  color: ${colors.xanadu};
  outline: none;
  border: solid 1px rgba(77,81,88,0.3);
  ${({hasError}) => hasError && `border-color: ${colors.rose}`};
  transition: all 200ms;
  border-radius: 2px;
  background: ${({hasError}) => hasError ? `${colors.rose}30` : 'transparent'};
  &:focus {
    border-color: ${colors.lightBlue};
    background: ${`${colors.lightBlue}30`};
  }
`;

const Label = styled.label`
  display: block;
  z-index: 1;
  font-weight: bold;
  & > p {
    ${({hasError}) => `color: ${hasError ? colors.rose : colors.xanadu}`};
    font-size: 13px;
    margin: 0;
  }
`;

const Error = styled.div`
  height: 14px;
  position: absolute;
  bottom: -12px;
  left: 0;
  color: ${colors.rose};
  font-size: 12px;
  transition: transform 200ms;
  transform: scale(0);
  &.show-enter {
    transform: scale(0);
  }
  &.show-enter-active {
    transform: scale(1);
  }
  &.show-enter-done {
    transform: scale(1);
  }
  &.show-exit {
    transform: scale(1);
  }
  &.show-exit-active {
    transform: scale(0);
  }
  &.show-exit-done {
    transform: scale(0);
  }
`;

const TextField = ({
  label = '',
  errors = [],
  required = false,
  multiline,
  ...rest
}) => (
  <Container>
    {label && <Label hasError={errors.length > 0}>
      <p>{label + (required ? '*' : '')}</p>
      {multiline
        ? <MultiLine required={required} hasError={errors.length > 0} {...rest}/>
        : <Input required={required} hasError={errors.length > 0} {...rest}/>
      }
    </Label>}
    <CSSTransition in={errors.length > 0} timeout={200} classNames="show">
      <Error>
        {(errors || []).map(error => error.message).join(' ')}
      </Error>
    </CSSTransition>
  </Container>
);

export default TextField;
