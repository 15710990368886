import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import YouTube from 'react-youtube';
import getYouTubeID from 'get-youtube-id';
import {gridMaxWidth} from '../../styles/theme';

const YoutubeContainer = styled.div`
  position: relative;
`;

const YoutubeOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
`;

const VideoContainer = styled.div`
  overflow: hidden;
  position: relative;
`;

const VerticallyStretchedVideo = styled.video`
  height: 100%;
  min-width: 100%;
  ${({aspectratio}) => `
    width: ${100 * aspectratio}%;
    min-height: ${100 / aspectratio}%;
  `}
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const HorizontallyStrechedVideo = styled.video`
  width: 100%;
  min-height: 100%;
  ${({aspectratio}) => `
    height: ${100 * aspectratio}%;
    min-width: ${100 / aspectratio}%;
  `}
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const NativeImg = styled.img`
  margin: 0;
`;

const Media = ({
  media,
  mediaWidth,
  height = 0,
  aspectRatio = 16 / 9
}) => {
  const {videoEmbedURL, file} = media || {};
  const [youtubeLoaded, setYoutubeLoaded] = React.useState(false);
  const fileType = file?.mime.split('/')[0];
  const VideoComponent = aspectRatio > 16 / 9
    ? HorizontallyStrechedVideo
    : VerticallyStretchedVideo;
  if (!(videoEmbedURL || file)) return null;
  return (
    <>
      {fileType === 'image'
        ? file.file?.extension === 'svg'
          ? (
            <NativeImg
              src={file.file?.publicURL}
              style={{height: `${Math.max(height, Math.ceil(mediaWidth / aspectRatio))}px`}}
              alt={file?.alternativeText}
            />
          )
          : (
            <Img
              fluid={file.file?.childImageSharp?.fluid}
              style={{height: `${Math.max(height, Math.ceil(mediaWidth / aspectRatio))}px`}}
              alt={file?.alternativeText}
            />
          )
        : fileType === 'video'
          ? (
            <VideoContainer
              style={{
                width: `${Math.min(gridMaxWidth, mediaWidth)}px`,
                height: `${Math.max(height, Math.ceil(mediaWidth / aspectRatio))}px`
              }}
            >
              <VideoComponent
                autoPlay
                muted
                loop
                aspectratio={aspectRatio}
              >
                <source src={file.file.publicURL} type={file.mime} />
              </VideoComponent>
            </VideoContainer>
          )
          : (
            <YoutubeContainer
              style={{
                width: `${Math.min(gridMaxWidth, mediaWidth)}px`,
                height: Math.ceil(Math.min(gridMaxWidth, mediaWidth) * 0.5625)
              }}
            >
              {!youtubeLoaded && <YoutubeOverlay />}
              <YouTube
                videoId={getYouTubeID(videoEmbedURL)}
                onReady={() => setTimeout(() => setYoutubeLoaded(true), 1000)}
                opts={{
                  width: `${Math.min(gridMaxWidth, mediaWidth)}px`,
                  height: Math.max(height, Math.ceil(mediaWidth / aspectRatio)),
                  playerVars: {
                    modestbranding: 1
                  }
                }}
              />
            </YoutubeContainer>
          )
      }
    </>
  );
};

export default Media;
