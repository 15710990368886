import window from './window';

export const getRefDimensions = ref => {
  const {width, height} = ref.current?.getBoundingClientRect?.() ?? {};
  return {width, height};
};

export const getWindowDimensions = () => ({
  width: window.innerWidth,
  height: window.innerHeight,
});
