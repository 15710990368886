import React from 'react';
import styled from 'styled-components';
import CloseIcon from '../../../images/svgsComponents/Close.svg';
import SearchIcon from '../../../images/svgsComponents/Search.svg';
import {navigate} from 'gatsby';
import {NavigationContext} from '../../../services/navigation';
import {colors, devices} from '../../../styles/theme';
/* eslint-disable-next-line atomic-design/hierarchical-import */
import {CloseIconContainer} from './styles';

const SearchContainer = styled.div`
  opacity: ${({hide}) => hide ? '0' : '1'};
  pointer-events: ${({hide}) => hide ? 'none' : 'auto'};
  background: ${({hide}) => hide ? 'transparent' : colors.blue};
  transition: all 500ms;
  position: fixed;
  z-index: 6;
  padding: 8px;
  width: 100%;
  display: flex;
  align-items: center;
  @media ${devices.tabletLandscape} {
    padding-right: 0;
  }
  @media ${devices.desktop} {
    padding: 12px 0 12px 12px;
  }
  @media ${devices.desktop} {
    padding: 16px 0 16px 16px;
  }
`;

const CloseSearchContainer = styled(CloseIconContainer)`
  margin-right: 0;
  margin-left: auto;
`;

const SearchLogoContainer = styled(CloseIconContainer)`
  transition: transform 500ms;
  margin: 0;
`;

const FormContainer = styled.div`
  margin: 0 16px;
  @media ${devices.tabletLandscape} {
    margin: 0 20px;
  }
  @media ${devices.desktopL} {
    margin: 0 24px;
  }
`;

const SearchInputContainer = styled.div`
  position: relative;
  &:after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    height: 1px;
    width: 100%;
    background: ${colors.lightBlue};
  }
`;

const SearchInput = styled.input`
  height: 40px;
  padding-top: 4px;
  font-size: 20px;
  color: white;
  width: 280px;
  outline: none;
  background: transparent;
  border: none;
  @media ${devices.tabletLandscape} {
    padding-top: 6px;
    font-size: 22px;
  }
  @media ${devices.desktopL} {
    padding-top: 8px;
    font-size: 24px;
  }
`;

const Search = () => {
  const [searchQuery, setSearchQuery] = React.useState('');
  const {
    isSearchActive,
    setIsSearchActive,
    setIsMenuOpen,
    setIsMenuExpanded
  } = React.useContext(NavigationContext);
  const inputRef = React.useRef();
  React.useEffect(() => {
    isSearchActive && inputRef.current.focus();
  }, [isSearchActive]);
  const handleSubmit = e => {
    e.preventDefault();
    setSearchQuery('');
    navigate(`/zoeken?q=${searchQuery}`);
    setIsSearchActive(false);
    setIsMenuOpen(false);
    setIsMenuExpanded(false);
  };
  return (
    <SearchContainer hide={!isSearchActive}>
      <SearchLogoContainer
        color={colors.blue}
        inverted
        onClick={() => navigate(`/zoeken?q=${searchQuery}`)}
        style={{
          transform: `translateX(${isSearchActive ? 0 : -100}%)`
        }}
      >
        <SearchIcon />
      </SearchLogoContainer>
      <FormContainer>
        <form onSubmit={handleSubmit} style={{margin: 0}}>
          <SearchInputContainer>
            <SearchInput
              ref={inputRef}
              value={searchQuery}
              onChange={e => setSearchQuery(e.target.value)}
              placeholder="What are you looking for?"
            />
          </SearchInputContainer>
        </form>
      </FormContainer>
      <CloseSearchContainer color={colors.blue} onClick={() => setIsSearchActive(false)}>
        <CloseIcon />
      </CloseSearchContainer>
    </SearchContainer>
  );
};

export default Search;
