import styled from 'styled-components';
import {Link} from 'gatsby';
import {colors, devices} from '../../styles/theme';

const FooterLink = styled(Link)`
  font-size: 15px;
  margin-bottom: 4px;
  text-decoration: none;
  color: ${colors.grey};
  @media ${devices.tabletPortrait} {
    margin: 0;
  }
  @media ${devices.desktop} {
    font-size: 14px;
  }
`;

export default FooterLink;
