import React from 'react';
import styled from 'styled-components';
import {Footer, TopBar} from '.';
import useResize from '../../hooks/useResize';
import {breakpoints, colors, devices, gridMaxWidth} from '../../styles/theme';
import {getRefDimensions, getWindowDimensions} from '../../utils/dimensions';
import {hex2rgba} from '../../utils/hex2rgb';
import './layout.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
/* eslint-disable atomic-design/hierarchical-import */
import Search from './Navigation/NavigationSearch';
import {DesktopMenu, MobileMenu} from './Navigation';
/* eslint-enable atomic-design/hierarchical-import */
import NavigationProvider, {NavigationContext} from '../../services/navigation';
import {Helmet} from 'react-helmet';

const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  min-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
`;

const ContentContainer = styled.main`
  display: grid;
  grid-auto-rows: minmax(min-content, max-content);
  grid-gap: 0px;
  width: 100%;
  max-width: ${gridMaxWidth}px;
  flex: 1;
  grid-template-columns: repeat(4, 1fr);
  @media ${devices.tabletLandscape} {
    grid-template-columns: repeat(8, 1fr);
  }
`;

const GridTracks = styled.div`
  display: flex;
  position: absolute;
  z-index: -1;
  max-width: ${gridMaxWidth}px;
  width: 100%;
  height: 100%;
  pointer-events: none;
`;

const GridLine = styled.div`
  height: 100%;
  flex: 1;
  border-left: 1px solid ${hex2rgba(colors.grey, 0.1)};
  &:first-child {
    border-left: none;
  }
  @media (min-width: ${gridMaxWidth + 200}px) {
    &:first-child {
      border-left: 1px solid ${hex2rgba(colors.grey, 0.1)};
    }
    &:last-child {
      border-right: 1px solid ${hex2rgba(colors.grey, 0.1)};
    }
  }
`;

export const PageContent = ({gridWidth, topBarHeight, children}) => (
  <ContentContainer
    gridwidth={gridWidth}
    style={{marginTop: topBarHeight}}
  >
    {children}
  </ContentContainer>
);

const PageContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
`;

const Page = ({children, topBarHeight}) => {
  const {
    setIsMenuExpanded,
    setHoveredMenuRoute,
    setIsMenuOpen
  } = React.useContext(NavigationContext);
  return (
    <PageContentContainer
      style={{paddingTop: topBarHeight + 24}}
      onMouseEnter={() => {
        setIsMenuExpanded(false);
        setHoveredMenuRoute(null);
      }}
      onClick={() => setIsMenuOpen(false)}
    >
      {children}
    </PageContentContainer>
  );
};

const Layout = ({children}) => {
  const [gridWidth, setGridWidth] = React.useState(0);
  const [topBarHeight, setTopBarHeight] = React.useState(0);
  const topBarRef = React.useRef();
  const resizeTrigger = useResize();
  React.useEffect(() => {
    const topBarHeight = getRefDimensions(topBarRef);
    setTopBarHeight(topBarHeight.height);
    setGridWidth(Math.min(gridMaxWidth, getWindowDimensions().width));
  }, [resizeTrigger]);
  const noOfColumns = gridWidth > breakpoints.tabletLandscape ? 8 : 4;
  return (
    <NavigationProvider>
      <Helmet>
        <script
          id="Cookiebot"
          src="https://consent.cookiebot.com/uc.js"
          data-cbid="d21f92d0-be3f-4447-b9a5-7f586627d983"
          type="text/javascript"
          async
        />
      </Helmet>
      <LayoutContainer>
        <TopBar ref={topBarRef} />
        <Search />
        <DesktopMenu />
        <MobileMenu />
        <GridTracks>
          {Array.from({length: noOfColumns}, (_, index) =>
            <GridLine key={index} />
          )}
        </GridTracks>
        <Page topBarHeight={topBarHeight}>
          {children}
        </Page>
        <Footer />
      </LayoutContainer>
    </NavigationProvider>
  );
};

export default Layout;
