import React from 'react';
/* eslint-disable-next-line atomic-design/hierarchical-import */
import * as S from './styles';
import CloseIcon from '../../../images/svgsComponents/Close.svg';
import PhoneIcon from '../../../images/svgsComponents/Contact.svg';
import Chevron from '../../../images/svgsComponents/Chevron.svg';
import {colors, devices} from '../../../styles/theme';
import {Button, H2, H6, Link} from '../../atoms';
import {CSSTransition} from 'react-transition-group';
import {hex2rgba, hex2rgb} from '../../../utils/hex2rgb';
import styled from 'styled-components';
import useNavigationData from '../../../queries/useNavigationData';
import {NavigationContext} from '../../../services/navigation';
import WhiteTextLogo from '../../../assets/images/whiteTextLogo.svg';

const ContactContainer = styled.div`
  padding: 16px;
  background: ${colors.orange};
  display: flex;
`;

const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const GetInTouch = ({data, onButtonClick}) => (
  <ContactContainer>
    <S.LogoContainer style={{margin: '-4px 12px 0 0'}} color={colors.orange} inverted disabled>
      <PhoneIcon />
    </S.LogoContainer>
    <ContactInfo>
      <H6 style={{color: 'white', fontWeight: 'bold'}}>{data.title}</H6>
      <p style={{margin: '12px 0', color: 'white'}}>{data.subtitle}</p>
      {data.button && (
        <Link
          to={data.button.link}
          style={{textDecoration: 'none'}}
          onClick={onButtonClick}
        >
          <Button type={data.button.type}>{data.button.text}</Button>
        </Link>
      )}
    </ContactInfo>
  </ContactContainer>
);

const Arrow = styled(Chevron)`
  width: 32px;
  height: 32px;
  transition: all 150ms;
  & > path {
    fill: ${({hovered}) => hovered
    ? `${hex2rgb(colors.lightBlue)}`
    : 'rgb(255, 255, 255)'}
  }
  transform: ${({hovered}) => `translateX(${hovered ? '4px' : 0})`};
  @media ${devices.desktopXL} {
    width: 40px;
    height: 40px;
  }
`;

const DesktopMenu = () => {
  const {routes, contact} = useNavigationData();
  const {
    isMenuOpen,
    setIsMenuOpen,
    isMenuExpanded,
    setIsMenuExpanded,
    hoveredMenuRoute,
    setHoveredMenuRoute,
  } = React.useContext(NavigationContext);
  return (
    <>
      <CSSTransition in={isMenuOpen} timeout={400} classNames="open">
        <S.DesktopMenuContainer style={{
          boxShadow: hoveredMenuRoute
            ? `inset -64px 0 64px -64px ${hex2rgba(colors.deepBlue, 0.8)}`
            : 'none'
        }}>
          <S.TopContainer onMouseEnter={() => {
            setIsMenuExpanded(false);
            setHoveredMenuRoute(null);
          }}>
            <S.CloseIconContainer onClick={() => setIsMenuOpen(false)}>
              <CloseIcon />
            </S.CloseIconContainer>
            <S.MenuHomeLink
              to="/"
              onClick={() => {
                setHoveredMenuRoute(null);
                setIsMenuExpanded(false);
                setIsMenuOpen(false);
              }}
            >
              <S.AmpelmannLogo src={WhiteTextLogo}/>
            </S.MenuHomeLink>
          </S.TopContainer>
          <S.NavigationContainer>
            <S.MainRoutes>
              {routes.main.map((mainRoute, index) =>
                mainRoute.isDeep
                  ? (
                    <S.DeepRouteLink
                      key={mainRoute.name}
                      hovered={hoveredMenuRoute === index + 1}
                      onMouseEnter={() => {
                        setHoveredMenuRoute(index + 1);
                        setIsMenuExpanded(true);
                      }}
                    >
                      <S.DeepMainRouteName hovered={hoveredMenuRoute === index + 1}>
                        {mainRoute.name}
                      </S.DeepMainRouteName>
                      <Arrow hovered={hoveredMenuRoute === index + 1}/>
                    </S.DeepRouteLink>
                  ) : (
                    <S.RouteName
                      key={mainRoute.name}
                      to={mainRoute.to}
                      style={{textDecoration: 'none'}}
                      onMouseEnter={() => {
                        setIsMenuExpanded(false);
                        setHoveredMenuRoute(null);
                      }}
                      onClick={() => setIsMenuOpen(false)}
                    >
                      <H2>{mainRoute.name}</H2>
                    </S.RouteName>
                  )
              )}
            </S.MainRoutes>
            <S.SecondaryRoutes>
              {routes.secondary.map(secondaryRoute => (
                <S.RouteName
                  key={secondaryRoute.name}
                  to={secondaryRoute.to}
                  style={{textDecoration: 'none'}}
                  onMouseEnter={() => {
                    setIsMenuExpanded(false);
                    setHoveredMenuRoute(null);
                  }}
                  onClick={() => setIsMenuOpen(false)}
                >
                  <H6>{secondaryRoute.name}</H6>
                </S.RouteName>
              ))}
            </S.SecondaryRoutes>
            <S.OtherRoutes>
              {routes.rest.map(secondaryRoute => (
                <S.RouteName
                  key={secondaryRoute.name}
                  to={secondaryRoute.to}
                  style={{textDecoration: 'none'}}
                  onMouseEnter={() => {
                    setIsMenuExpanded(false);
                    setHoveredMenuRoute(null);
                  }}
                  onClick={() => setIsMenuOpen(false)}
                >
                  <H6>{secondaryRoute.name}</H6>
                </S.RouteName>
              ))}
            </S.OtherRoutes>
          </S.NavigationContainer>
          {contact && (
            <GetInTouch
              data={contact}
              onButtonClick={() => {
                setIsMenuExpanded(false);
                setIsMenuOpen(false);
              }}
            />
          )}
        </S.DesktopMenuContainer>
      </CSSTransition>
      <CSSTransition in={isMenuExpanded} timeout={200} classNames="deep-menu-container">
        <S.DeepMenuContainer>
          <S.DeepMenu hovered={hoveredMenuRoute}>
            {hoveredMenuRoute && routes.main[hoveredMenuRoute - 1].subRoutes.map(subRoute => (
              <Link
                key={subRoute.name}
                to={subRoute.to}
                style={{textDecoration: 'none'}}
                onClick={() => {
                  setHoveredMenuRoute(null);
                  setIsMenuExpanded(false);
                  setIsMenuOpen(false);
                }}
              >
                <S.DeepLink>
                  <S.DeepLinkName>
                    {subRoute.name}
                  </S.DeepLinkName>
                </S.DeepLink>
              </Link>
            ))}
          </S.DeepMenu>
        </S.DeepMenuContainer>
      </CSSTransition>
    </>
  );
};

export default DesktopMenu;
