import React from 'react';
import styled from 'styled-components';
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
} from 'react-share';
import {useLocation} from '@reach/router';
import {GridComponentContainer, H5} from '../../atoms';
import {colors, devices} from '../../../styles/theme';

const Container = styled(GridComponentContainer)`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-auto-rows: minmax(min-content, max-content);
  grid-column: 1 / 5;
  @media ${devices.tabletPortrait} {
    grid-column: 1 / 9;
    grid-template-columns: repeat(16, 1fr);
  }
  ${H5} {
    grid-column: 1 / 6;
    padding: 0 16px;
    color: ${colors.blue};
    margin-bottom: 24px;
    @media ${devices.tabletPortrait} {
      padding: 0;
      grid-column: 4 / 10;
      grid-template-columns: repeat(16, 1fr);
    }
  }
  `;

const IconContainer = styled.div`
  display: flex;
  grid-column: 1 / 9;
  justify-content: space-around;
  & > button {
    width: 40px;
    height: 40px;
    outline: none;
    & > svg {
      width: 40px;
      height: 40px;
    }
    &:hover rect {
      fill: ${colors.lightBlueDark}!important;
    }
    &:active rect {
      fill: ${colors.lightBlueDarker}!important;
    }
  }
  @media ${devices.tabletPortrait} {
    justify-content: space-between;
    & > button {
      width: 48px;
      height: 48px;
      & > svg {
        width: 48px;
        height: 48px;
      }
    }
    grid-column: 5 / 13;
    grid-template-columns: repeat(16, 1fr);
  }
  @media ${devices.tabletLandscape} {
    & > button {
      width: 56px;
      height: 56px;
      & > svg {
        width: 56px;
        height: 56px;
      }
    }
  }
  @media ${devices.desktop} {
    & > button {
      width: 64px;
      height: 64px;
      & > svg {
        width: 64px;
        height: 64px;
      }
    }
  }
`;

const Share = ({title, facebook, twitter, mail, linkedIn, appearAnimation}) => {
  const location = useLocation();
  const url = location.href;
  const iconProps = {
    bgStyle: {
      fill: colors.lightBlue,
    },
    borderRadius: 2,
  };
  return (
    <Container appearFrom={appearAnimation?.appearFrom}>
      <H5>{title}</H5>
      <IconContainer>
        {linkedIn && <LinkedinShareButton {...linkedIn} url={url}>
          <LinkedinIcon {...iconProps}/>
        </LinkedinShareButton>}
        {facebook && <FacebookShareButton {...facebook} url={url}>
          <FacebookIcon {...iconProps} />
        </FacebookShareButton>}
        {twitter && <TwitterShareButton
          {...twitter}
          hashtags={
            twitter.hashtags
              ? twitter.hashtags.replace(/ /g, '').split(',')
              : []
          }
          related={
            twitter.related
              ? twitter.related.replace(/ /g, '').split(',')
              : []
          }
          url={url}
        >
          <TwitterIcon {...iconProps} />
        </TwitterShareButton>}
        {mail && <EmailShareButton {...mail} url={url}>
          <EmailIcon {...iconProps} />
        </EmailShareButton>}
      </IconContainer>
    </Container>
  );
};

export default Share;
