import React from 'react';
import {useInView} from 'react-intersection-observer';
import {CSSTransition} from 'react-transition-group';
import styled from 'styled-components';
import {devices} from '../../styles/theme';
import {appear} from '../../styles/transitions';
import {useIsJsEnabled} from 'gatsby-plugin-js-fallback';

const GridComponentContainer = styled.div`
  grid-column: 1/5;
  margin: 24px 0;
  ${({appearFrom}) => appearFrom && appear[appearFrom]('fade', 800)}
  & > a {
    font-size: 16px;
  }
  @media (min-width: 1200px) {
    font-size: 18px;
    & > a {
      font-size: 18px;
    }
  }
  @media ${devices.tabletLandscape} {
    margin: 40px 0;
    // default to full width
    grid-column: ${({fromColumn = 1, widthInColumns = 8}) =>
    `${fromColumn} / ${fromColumn + widthInColumns}`};
  }
`;

/* eslint-disable react/display-name */
const GridComponent = React.forwardRef((
  {
    children,
    className,
    fromColumn,
    widthInColumns,
    appearFrom,
    style
  }, ref) => {
  const [_observerRef, inView] = useInView({
    triggerOnce: true
  });
  const isJsEnabled = useIsJsEnabled();
  // In some cases a ref is used when rendering a GridComponent, that is why we use the forwardRef
  // In those cases we make use of the setRefs function to merge these external refs
  // with the ones used by the observer.
  // When an external ref is not used, only the ref provided by the intersection observer is used
  const setRefs = React.useCallback(
    node => {
      if (!ref) return;
      ref.current = node;
      _observerRef(node);
    },
    [_observerRef, ref],
  );
  return (
    <CSSTransition in={inView} timeout={800} classNames="fade">
      <GridComponentContainer
        ref={ref ? setRefs : _observerRef}
        className={className}
        fromColumn={fromColumn}
        widthInColumns={widthInColumns}
        appearFrom={appearFrom}
        style={{
          ...style,
          ...(!isJsEnabled
            ? {
              opacity: 1,
              transform: 'none'
            } : {}
          )
        }}
      >
        {children}
      </GridComponentContainer>
    </CSSTransition>
  );
});
export default GridComponent;
