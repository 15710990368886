import React from 'react';
import styled from 'styled-components';
import {colors, devices} from '../../../styles/theme';
import {GridComponentContainer, TextContainer, Markdown} from '../../atoms';

const Testimony = styled.div`
  margin: 0;
  font-size: 20px;
  line-height: 1.1;
  font-weight: normal;
  & > p {
    margin: 0;
  }
  @media ${devices.tabletLandscape}{
    font-size: 24px;
  }
  @media ${devices.desktop}{
    font-size: 30px;
  }
  @media ${devices.desktopXL}{
    font-size: 32px;
    line-height: 1;
  }
`;

const Signature = styled.div`
  text-transform: uppercase;
  font-size: 14px;
  margin-left: auto;
  @media ${devices.tabletLandscape}{
    font-size: 16px;
    margin-top: 8px;
  }
  @media ${devices.desktop}{
    margin-top: 16px;
  }
`;

const TestimonyContainer = styled(TextContainer)`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(4, 1fr);
  @media ${devices.tabletLandscape}{
    grid-template-columns: repeat(6, 1fr);
    ${Testimony} {
      grid-column: 2/6;
    }
    ${Signature} {
      grid-column: 2/6;
    }
  }
`;

const Container = styled(GridComponentContainer)`
  padding: 32px 0;
  ${Testimony} {
    grid-column: 1/5;
  }
  ${Signature} {
    grid-column: 1/5;
  }
  @media ${devices.tabletLandscape}{
    grid-template-columns: repeat(6, 1fr);
    padding: 48px 0;
    ${Testimony} {
      grid-column: 2/6;
    }
    ${Signature} {
      grid-column: 2/6;
    }
  }
`;

const testimonyColors = {
  lightGrey: colors.blue,
  green: colors.lightGrey,
  blue: colors.lightGrey,
  lightBlue: colors.blue
};

const signatureColors = {
  lightGrey: colors.grey,
  green: colors.lightGrey,
  blue: colors.lightBlue,
  lightBlue: colors.lightGrey
};

const Testimonial = ({
  fullName,
  testimony,
  company,
  background,
  startingColumn,
  appearAnimation
}) => {
  const testimonyColor = testimonyColors[background];
  const signatureColor = signatureColors[background];
  return (
    <Container
      fromColumn={startingColumn}
      widthInColumns={6}
      appearFrom={appearAnimation?.appearFrom}
      style={{background: colors[background]}}
    >
      <TestimonyContainer>
        <Testimony style={{color: testimonyColor}}>
          <Markdown>
            {fullName ? `"${testimony}"` : testimony}
          </Markdown>
        </Testimony>
        {fullName?.trim()?.length > 0 && <Signature style={{color: signatureColor}}>
          {`- ${fullName}${company ? `, ${company}` : ''}`}
        </Signature>}
      </TestimonyContainer>
    </Container>
  );
};

export default Testimonial;
