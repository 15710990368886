import React from 'react';
import styled from 'styled-components';

import {Subtitle} from '../atoms';

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 8px 16px 0;
  background: ${({color}) => color};
  padding: 4px 8px 0;
  border-radius: 2px;
  color: white;
`;

const Label = styled(Subtitle)`
  line-height: 28px;
  text-transform: uppercase;
  font-weight: bold;
`;

const StoryLabel = ({color, children, className}) =>
  <LabelContainer color={color} className={className}>
    <Label>{children}</Label>
  </LabelContainer>;

export default StoryLabel;

