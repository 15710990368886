import styled from 'styled-components';
import {devices} from '../../styles/theme';

const TextContainer = styled.div`
  padding: 0 16px;
  @media ${devices.tabletPortrait}{
    padding: 0 18px;
  }
  @media ${devices.desktop}{
    padding: 0 20px;
  }
`;

export default TextContainer;
